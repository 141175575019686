import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { FiLoader } from "react-icons/fi";

const MotifSuspension = ({ onSubmit, onClickAnnul, isLoading }) => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [selectedMotifs, setSelectedMotifs] = useState([]);

  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setSelectedMotifs([...selectedMotifs, value]);
    } else {
      setSelectedMotifs(selectedMotifs.filter((motif) => motif !== value));
    }
  };

  const checkboxes = [
    {
      label: "Révisions en cours",
      value: "Révisions en cours",
      id: "revisions",
    },
    {
      label: "Attente d'approbation",
      value: "Attente d'approbation",
      id: "attentes",
    },
    {
      label: "Problèmes techniques",
      value: "Problèmes techniques",
      id: "problemes",
    },
    {
      label: "Étude de marché en cours",
      value: "Étude de marché en cours",
      id: "etudes",
    },
    {
      label: "Besoin de rassembler plus de ressources",
      value: "Besoin de rassembler plus de ressources",
      id: "besoins",
    },
    {
      label: "Problèmes de gestion de projet",
      value: "Problèmes de gestion de projet",
      id: "gestions",
    },
    {
      label: "Problèmes de conformité",
      value: "Problèmes de conformité",
      id: "conformites",
    },
    { label: "Autre", value: "Autre", id: "autres" },
  ];

  const isAutreSelected = selectedMotifs.includes("Autre");
  const onSubmitForm = (data) => {
    const filteredMotifs = selectedMotifs.filter((motif) => motif !== "Autre");
    if (isAutreSelected && data.motif_suspension) {
      filteredMotifs.push(data.motif_suspension);
    }
    onSubmit(filteredMotifs.join(", "));
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)} className="p-8">
      {checkboxes.map(({ label, id, value }) => (
        <div key={id} className="mb-2">
          <input
            className="relative float-left -ms-[1.5rem] me-[6px] h-[1.125rem]
            w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid
            border-[var(--secondary-color)] outline-none before:pointer-events-none before:absolute
            before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full
            before:bg-[var(--secondary-color)] before:opacity-0 before:shadow-checkbox
            checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px
            checked:after:ms-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45
            checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-[var(--secondary-color)]
            checked:after:bg-[var(--secondary-color)] checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-black/60
            focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-black/60
            focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem]
            focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-checkbox
            checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:-mt-px checked:focus:after:ms-[0.25rem]
            checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none
            checked:focus:after:border-[0.125rem] checked:focus:after:border-l-0 checked:focus:after:border-t-0 checked:focus:after:border-solid
            checked:focus:after:border-[var(--secondary-color)] checked:focus:after:bg-[var(--secondary-color)]"
            type="checkbox"
            id={id}
            value={value}
            {...register("motifs", { required: "Il faut que vous sélectionnez au moins un motif" })}
            onChange={handleCheckboxChange}
          />
          <label htmlFor={id} className="ml-2">
            {label}
          </label>
        </div>
      ))}
      {errors.motifs && <p className="text-red-500">{errors.motifs.message}</p>}
      {isAutreSelected && (
        <>
          <label
            htmlFor="description"
            className="text-sm font-medium mb-2 sm:mb-1 text-[var(--nonaryTextColor)]"
          >
            Pourquoi souhaitez-vous rejeter ce projet sur Elikia Funding? <br />
            Veuillez fournir une brève explication des raisons de ce rejet.
          </label>
          <textarea
            id="description"
            name="motif_suspension"
            rows="3"
            cols="30"
            {...register("motif_suspension")}
            className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
          ></textarea>
        </>
      )}
      <div className="flex justify-end items-baseline gap-4 sm:justify-evenly mt-8 mb-4">
        <button
          onClick={onClickAnnul}
          type="button"
          className="text-white items-center rounded-md px-4 py-2 bg-[#369E8A]"
        >
          Annuler
        </button>
        <button
          type="submit"
          className="text-white rounded-md px-4 py-2 bg-[#0C8CE9]"
        >
          { isLoading && isLoading ? (
            <div className="flex items-center gap-1">
              Suspendre
              <FiLoader className="text-white text-xl animate-spin" />
            </div>
          ) : (
            "Suspendre"
          )}
        </button>
      </div>
    </form>
  );
};

export default MotifSuspension;