import React from "react";
/* import { Progress } from "@material-tailwind/react";
 */
function progressBar({ value }) {
  return (
    <div className="flex items-center gap-1">
{/*       <Progress value={value} color="green"/>
 */}      {value}%
     {/*  <Typography color="blue-gray" size="lg">
          {value}%
        </Typography> */}
    </div>
  );
}

export default progressBar;
