import React from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux/features/userSliceWithTokenValidation";
import { FiLoader } from "react-icons/fi";

const SubmitButton = ({ buttonText, onClick, icon, style, isLoading }) => {
  const currentUser = useSelector(getUser);

  const isEntrepreneur = currentUser?.data?.user_type === "entrepreneur";
  const isInvesteur = currentUser?.data?.user_type === "investisseur";

  const buttonStyle = {
    padding: "0.5rem 1.25rem",
    marginTop: "0.5rem",
    color: "white",
    borderRadius: "0.25rem",
    backgroundColor: isEntrepreneur || isInvesteur ? "var(--secondary-color)" : "var(--backgroundOrange)",
  };

  const containerStyle = {
    display: "flex",
    justifyContent: isEntrepreneur || isInvesteur ? "flex-end" : "flex-start",
  };

  return (
    <div className="flex items-center gap-2" style={containerStyle}>
      <button
        type="submit"
        onClick={onClick}
        style={{ ...buttonStyle, ...style }}
      >
        {isLoading && isLoading ? (
          <div className="flex items-center gap-1">
            <span>{buttonText}</span>
            <FiLoader className="text-white text-xl animate-spin" />
          </div>
        ) : (
          <div className="flex items-center justify-center gap-2">
            <span>{buttonText}</span>
            {icon && <span>{icon}</span>}
          </div>
        )}
      </button>
    </div>
  );
};

export default SubmitButton;
