// src/Dashboard.js
import React, { useState } from "react";
import Titre from "components/Titre";
import PieChart from "components/Chart";
import TableComponent from "components/TableComponents";
import Loader2 from "components/Loader/Loader2";
import { useGetSubscriberIdQuery } from "actions/souscrire/queries";
import { getUser } from "redux/features/userSliceWithTokenValidation";
import {
  useGetProjetSecteurByInvestisseurStatistiqueIdQuery,
} from "actions/projets/queries";
import { LiaSearchSolid } from "react-icons/lia";
import { useSelector } from "react-redux";
import { formDate } from "utils/date"
import styled from "styled-components";


const StyledHeader =styled.div `
th {
  color:var(--secondary-color);
  }
td{
 padding:13px
 }  
`

function Dashboard() {
  const { data: projectSouscrit, isLoading } = useGetSubscriberIdQuery();
  const filteredProject = projectSouscrit?.results;
  const current_user = useSelector(getUser);
  const id = current_user?.data?.id;
  const { data: projectAndSecteurByInvestisseurStatistique } =
    useGetProjetSecteurByInvestisseurStatistiqueIdQuery(id);
  const projectAndSecteurStatique =
    projectAndSecteurByInvestisseurStatistique?.secteurs || [];
  const [search, setSearch] = useState("");
  const [selectedSecteur, setSelectedSecteur] = useState("");
  const [selectedProjet, setSelectedProjet] = useState("");
  console.log({ projectAndSecteurByInvestisseurStatistique });
  console.log({ id });
  const columns = [
    { id: 1, header: "Nom du Projet", accessor: "NomProjet" },
    { id: 2, header: "Nom de l'investisseur", accessor: "NomPorteurProjet" },
    { id: 3, header: "Secteurs d'Activité", accessor: "SecteursAct" },
    { id: 4, header: "Montant Élevé", accessor: "Montants" },
    { id: 5, header: "Personne Intéressée", accessor: "interesser" },
    { id: 6, header: "Date du projet", accessor: "date" },
  ];
  const data = filteredProject?.map((projetSouscrit) => ({
    NomProjet: projetSouscrit?.projet?.title || "Non défini",
    NomPorteurProjet: `${projetSouscrit?.user?.prenom} ${
      projetSouscrit?.user?.nom || "Non défini"
    } `,
    SecteursAct: projetSouscrit?.projet?.secteur?.[0]?.name || "Non défini",
    Montants: `${
      projetSouscrit?.projet?.montant || "0"
    } FCFA`,
    interesser: projetSouscrit?.projet?.nb_subscriber || "Non défini",
    date: formDate(projetSouscrit?.projet?.created_at) || "Non défini",
  }));
  // console.log({ filteredProject });
  const filteredData = data?.filter((row) => {
    const nomExists = row?.NomProjet && typeof row?.NomProjet === "string";
    const nomPorteurExists =
      row?.NomPorteurProjet && typeof row?.NomPorteurProjet === "string";
    const secteurMatches =
      selectedSecteur === "" || row?.SecteursAct === selectedSecteur;
    const projetMatches =
      selectedProjet === "" || row?.NomProjet === selectedProjet;
    if (nomExists && nomPorteurExists && secteurMatches && projetMatches) {
      return (
        row?.NomProjet.toLowerCase().includes(search.toLowerCase()) ||
        row?.NomPorteurProjet.toLowerCase().includes(search.toLowerCase())
      );
    }
    return false;
  });
  const secteur = projectAndSecteurStatique?.map((projet) => projet?.secteur);
  const dataValuesSecteur = projectAndSecteurStatique?.map((projet) =>
    parseFloat(projet?.pourcentage)
  );
  const pourcentage = projectAndSecteurStatique?.map(
    (projet) => projet?.pourcentage
  );
  const secteursUniques = filteredProject?.reduce((acc, projetSouscrit) => {
    const secteurName = projetSouscrit?.projet?.secteur?.[0]?.name;
    if (secteurName && !acc.includes(secteurName)) {
      acc.push(secteurName);
    }
    return acc;
  }, []);
  return (
    <>
      <Titre titre={"Dashboard"} bold={true} />
      <div className="grid grid-cols-2 lg:grid-cols-1 md:grid-cols-1 w-100 sm:grid-cols-1">
        <div>
          <PieChart
            titleChart={"Portefeuille"}
            labels={secteur}
            numberColor={"var(--secondary-color)"}
            showPercentages={true}
            total={
              projectAndSecteurByInvestisseurStatistique?.nombre_secteurs ||
              "0"
            }
            dataValues={dataValuesSecteur}
            percentages={pourcentage}
            fontSize={"40px"}
          />
        </div>
        <div className="">
          <PieChart
            titleChart={"Profit"}
            labels={secteur}
            numberColor={"var(--secondary-color)"}
            showPercentages={true}
            total={`${
              projectAndSecteurByInvestisseurStatistique?.nombre_secteurs || "0"
            }`}
            dataValues={dataValuesSecteur}
            percentages={pourcentage}
            fontSize={"40px"}
            flexDirection="column"
            display={"ruby"}
          />
        </div>
        <div>
          <PieChart
            titleChart={"Montant investi"}
            montantInvesti={`${
              projectAndSecteurByInvestisseurStatistique?.total_investi || "0"
            }FCFA`}
            labels={secteur}
            showPercentages={false}
            dataValues={dataValuesSecteur}
            cutout={"0%"}
            Labelpercentages={pourcentage}
            type="Pie2"
            flexTotal={"hidden"}
            flexTotalInvesti="flex"
          />
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-between items-center sm:flex-col">
            <Titre titre={"Projets Intéressés"} bold={true} size={"22px"} />
          <div className="flex items-center py-2 ps-3 mx-3 gap-4 border w-[40%] sm:w-full bg-white  rounded-md border-[var(--secondary-color)]">
            <div className="mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-[var(--secondary-color)]"
              >
                Projets
              </label>
              <select
                name="projet"
                value={selectedProjet}
                onChange={(e) => setSelectedProjet(e.target.value)}
                className="form control w-full px-2 py-2 border border-[var(--secondary-color)] rounded outline-0 text-[var(--secondary-color)]"
              >
                <option value="">Sélectionner un projet</option>
                {filteredProject?.map((projetSouscrit) => (
                  <option
                    key={projetSouscrit?.projet?.id}
                    value={projetSouscrit?.projet?.title}
                  >
                    {projetSouscrit?.projet?.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-[var(--secondary-color)]"
              >
                Secteurs
              </label>
              <select
                name="etapeA"
                value={selectedSecteur}
                onChange={(e) => setSelectedSecteur(e.target.value)}
                className="form control w-full px-2 py-2 border border-[var(--secondary-color)] rounded outline-0 text-[var(--secondary-color)]"
              >
                <option value="">Sélectionner un secteur</option>
                {secteursUniques?.map((secteur, index) => (
                  <option key={index.id} value={secteur}>
                    {secteur}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 p-3">
        <div className="flex items-center w-full relative">
              <span className="absolute ms-[-1px] p-3">
                <LiaSearchSolid className="text-[var(--secondary-color)] text-2xl" />
              </span>
              <input
                type="text"
                name="name"
                className="w-full px-12 py-2 border border-[var(--secondary-color)] rounded-full outline-0 text-[var(--septanaryBorderColor)]"
                placeholder="Rechercher un projet"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            </div>
        {isLoading ? (
          <Loader2 />
        ) : filteredData && filteredData.length > 0 ? (
          <StyledHeader>
          <TableComponent
            data={filteredData}
            columns={columns}
            bgColor={"#F3F6F9"}
            color={"#6A707E"}
            // size={"14px"}
          />
           </StyledHeader>
        ) : (
          <div className="text-xl text-red-500 pb-8">Pas de données</div>
        )}
      </div>
    </>
  );
}

export default Dashboard;