import React, { useState } from "react";
import Projets from "components/Projet";
import Titre from "components/Titre";
import { useGetProjetQuery } from "actions/projets/queries";
import { Outlet } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { LiaSearchSolid } from "react-icons/lia";

function Projet() {
  const { data: projectsData, isLoading } = useGetProjetQuery();
  const projects = projectsData?.results || [];
  // console.log({ projects });
  const secteursUniques = projects?.reduce((acc, projetFiltre) => {
    const secteurName = projetFiltre?.secteur?.[0]?.name;
    if (secteurName && !acc.includes(secteurName)) {
      acc.push(secteurName);
    }
    return acc;
  }, []);
  const [selectedSecteur, setSelectedSecteur] = useState("");
  const [search, setSearch] = useState("");
  const filteredProjects = projects.filter((project) => {
    if (selectedSecteur && project.secteur?.[0]?.name !== selectedSecteur) {
      return false;
    }
    if (search && !project.title.toLowerCase().includes(search.toLowerCase())) {
      return false;
    }
    return true;
  });
  return (
    <div>
      <div>
        <Titre titre={"Projets"} />
        <div className="flex justify-between items-center p-3 pt-0 sm:block sm:px-3">
          <div className="">
            <select
              name="etapeA"
              value={selectedSecteur}
              onChange={(e) => setSelectedSecteur(e.target.value)}
              className="form control w-full sm:mb-3 px-2 py-2 border border-[var(--secondary-color)] rounded outline-0 text-[var(--secondary-color)]"
            >
              <option value="">Sélectionner un secteur</option>
              {secteursUniques?.map((secteur, index) => (
                <option key={index.id} value={secteur}>
                  {secteur}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className="flex items-center w-full relative">
              <span className="absolute ms-[-1px] p-3">
                <LiaSearchSolid className="text-[var(--secondary-color)] text-2xl" />
              </span>
              <input
                type="text"
                name="name"
                className="w-full px-12 py-2 border border-[var(--secondary-color)] rounded-full outline-0 text-[var(--septanaryBorderColor)]"
                placeholder="Rechercher un projet"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 p-2">
          {isLoading ? (
            <Loader />
          ) : filteredProjects.length > 0 ? (
            filteredProjects.map((item) => (
              <Projets
                key={item.id}
                Link={`/projetsInvest/${item.id}`}
                Video={item?.url_demo}
                Secteur={item?.secteur}
                Statut={item?.statut}
                Titre={item?.title}
                Financement={item?.montant}
                Montant={item?.montant_part}
                className={"hidden"}
                bgColor={"var(--secondary-color)"}
                classInteresser={"flex"}
                Interreser={item?.nb_subscriber}
              />
            ))
          ) : (
            <div>Aucun projet disponible.</div>
          )}

          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Projet;
