import React from "react";
import logoBlanc from "assets/Images/ElikiaBlanc.png";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/userSliceWithTokenValidation";
import { PUBLIC_PATH } from "../../routes/navigation/navigationPaths";
import { IoLogOut } from "react-icons/io5";
import Notification from "components/Notification";
import { useGetIncubateurIdQuery } from "actions/auth/queries"
import useCurrentUser from "hooks/useCurrentUser";
import { API_STORAGE } from "routes/api/endpoints"

function NavbarIncubateurComponent({ bgColor, navbarContainerStyles }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userStatus = useCurrentUser();
  const id = userStatus?.id
  const { data: incubateurData } = useGetIncubateurIdQuery(id);
  const handleLogout = async () => {
    try {
      await dispatch(logout());
      // console.log("deconnexion");
      navigate(PUBLIC_PATH);
      // Redirect or perform any additional actions after successful logout
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 20px",
        height: 50,
        backgroundColor: bgColor ? bgColor : "var(--secondary-color)",
        borderBottom: "1px solid rgba(0,0,0,0.1)",
        ...navbarContainerStyles,
      }}
    >
      <div className="p-8 sm:p-0">
        <img src={logoBlanc} alt="Logo elikia" className="w-20 sm:w-18 sm:ml-[-16px]" />
      </div>
      <div className="flex justify-end items-center gap-6 sm:gap-0 sm:text-nowrap">
        <div className="flex justify-end items-center gap-2 sm:mr-4">
          <img src={ API_STORAGE + userStatus?.avatar } alt="Avatar" className=" min-w-8 max-w-8 min-h-8 max-h-8 rounded-2xl" />
          <div className="text-white text-sm sm:text-[12px]">
          <p>
    {userStatus?.user_type === "incubateur" ? incubateurData?.nom_incubateur : `${userStatus?.prenom} ${userStatus?.nom}`}
  </p>
            <p>{userStatus?.user_type}</p>
          </div>
        </div>
        <div>
          <Notification />
        </div>
        <div>
          <NavLink className="dropdown-item no-bg" to="" onClick={handleLogout}>
            <IoLogOut className="text-white text-3xl sm:text-2xl" />
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default NavbarIncubateurComponent;
