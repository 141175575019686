import React from "react";
import { FiLoader } from "react-icons/fi";

const ActionProjetIncubateur = ({
  disabledValider,
  disabledRejeter,
  disabledSuspendre,
  projet,
  onClick1,
  onClick2,
  onClick3,
  flex,
  isLoading,
}) => {
  return (
    <div className={`${flex}`}>
      {projet?.statut === "valider" && (
        <div>
          <div className="flex justify-center">
            <button className="text-white rounded-md px-4 py-3 w-96 mt-4 sm:w-full sm:mb-3 bg-[#369E8A] cursor-not-allowed">
              Déjà validé
            </button>
          </div>
          <div className="flex justify-evenly items-baseline gap-5 sm:justify-evenly mb-2">
            <button
              disabled={disabledSuspendre}
              onClick={onClick3}
              className="text-white bg-[#0C8CE9] rounded-md px-4 py-2 w-[190px] h-[46px] sm:w-[130px] sm:h-[40px]
              md:w-[130px] md:h-[46px] lg:w-[140px] lg:h-[46px]2xl:w-[225px] 2xl:h-[46px] mt-4"
            >
              {isLoading && isLoading ? (
                <div className="flex items-center gap-1">
                  Suspendre
                  <FiLoader className="text-white text-xl animate-spin" />
                </div>
              ) : (
                "Suspendre"
              )}
            </button>
            <button
              disabled={disabledRejeter}
              style={{ backgroundColor: "#FF0000" }}
              onClick={onClick2}
              className="text-white rounded-md px-4 py-2 w-[190px] h-[46px] sm:w-[130px] sm:h-[40px] md:w-[130px] md:h-[46px] lg:w-[140px] lg:h-[46px] 2xl:w-[225px] 2xl:h-[46px]"
            >
              {isLoading && isLoading ? (
                <div className="flex items-center gap-1">
                  Rejeter
                  <FiLoader className="text-white text-xl animate-spin" />
                </div>
              ) : (
                "Rejeter"
              )}
            </button>
          </div>
        </div>
      )}
      {projet?.statut === "rejeter" && (
        <button className="text-white rounded-md px-4 py-3 w-96 mt-4 sm:w-full sm:mb-3 bg-[#F46565] cursor-not-allowed">
          Projet rejeté
        </button>
      )}
      {projet?.statut === "suspendu" && (
        <button
          style={{
            backgroundColor: "#0C8CE980",
            cursor: "not-allowed",
          }}
          className="text-white rounded-md px-4 py-3 w-96 mt-4 sm:w-full sm:mb-3 bg-[#0C8CE980]"
        >
          Projet suspendu
        </button>
      )}
      { projet?.statut === "nouveau" && (
        <div className="flex justify-evenly items-baseline gap-5 sm:justify-evenly mb-2">
          <button
            disabled={disabledValider || isLoading}
            style={{ backgroundColor: "#369E8A" }}
            onClick={onClick1}
            className="text-white items-center rounded-md px-4 py-2"
          >
            {isLoading && isLoading ? (
              <div className="flex items-center gap-1">
                Valider
                <FiLoader className="text-white text-xl animate-spin" />
              </div>
            ) : (
              "Valider"
            )}
          </button>
          <button
            disabled={disabledRejeter || isLoading}
            style={{ backgroundColor: "#FF0000" }}
            onClick={onClick2}
            className="text-white rounded-md px-4 py-2"
          >
            {isLoading && isLoading ? (
              <div className="flex items-center gap-1">
                Rejeter
                <FiLoader className="text-white text-xl animate-spin" />
              </div>
            ) : (
              "Rejeter"
            )}
          </button>
        </div>
      )}
    </div>
  );
};

export default ActionProjetIncubateur;
