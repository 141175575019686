import SpliTemplateScreen from "layouts";
import NavbarComponent from "layouts/navbar/NavbarComponent";
import SidebarComponent from "layouts/sidebar/SidebarComponent"
import React from "react";
import { Outlet } from "react-router-dom";
import { links } from "routes/navigation/sidebarLinks";

/*
|--------------------------------------------------------------------------
| Dashboard avec un sidebar
|--------------------------------------------------------------------------
|
| Ce layout contient le sidebar ainsi que le navbar de l'application
| Il est appeler dans routes/navigation/privte.js en tant que children de RequireAuth
|
*/
const IncubateurShell = () => {
    return (
        <SpliTemplateScreen>
            <NavbarComponent bgColor={"var(--backgroundOrange)"}/>
            <SidebarComponent
                bgColor={"#fff"}
                activeLinkColor={"#fff"}
                activeLinkBgColor="var(--backgroundOrange)"
                linkColor="black"
                colorOnHover={"black"}
                resourceData={links}
            />
            <Outlet />
        </SpliTemplateScreen>
    )
}

export default IncubateurShell