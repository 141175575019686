import React from "react";
import Projets from "components/Projet";
import Titre from "components/Titre";
import { useGetProjetFinancerByInvestisseurQuery } from "../../../actions/projets/queries";
import { API_STORAGE } from "../../../routes/api/endpoints";
import { Outlet } from "react-router-dom";
import Loader from "components/Loader/Loader";
import { getUser } from "redux/features/userSliceWithTokenValidation"
import { useSelector } from "react-redux";

function ProjetFinancerInvest() {
  const currentUser = useSelector(getUser);
  const id = currentUser.data.id;
  console.log({ id });
  const {
    data: projectsData,
    isLoading,
  } = useGetProjetFinancerByInvestisseurQuery(id);
  const projects = projectsData?.results || [];
  console.log({ projectsData });
  return <div>
     <div>
      <Titre titre={"Projets financés"} />
      <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 p-2">
        {isLoading ? (
          <Loader />
        ) : projects.length > 0 ? (
          projects.map((item) => (
            <Projets
              key={item.projet?.id}
              Link={`/projetFinancerInvest/${item.projet?.id}`}
              Video={API_STORAGE + item?.projet?.video}
              Secteur={item?.secteur}
              Titre={item?.projet?.title}
              Financement={item?.projet?.montant}
              Montant={item?.projet?.montant_part}
              className={"hidden"}
              bgColor={"var(--secondary-color)"}
              Interreser={item?.projet?.nb_subscriber}
            />
          ))
        ) : (
          <div>Aucun projet disponible.</div>
        )}

        <Outlet />
      </div>
    </div>
  </div>;
}

export default ProjetFinancerInvest;
