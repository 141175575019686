import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getUser } from "redux/features/userSliceWithTokenValidation";

const ReseauSocial = ({ icon, link }) => {
    const currentUser = useSelector(getUser);

    const isIncubateur = currentUser?.data?.user_type === "incubateur";
    const backgroundColor = isIncubateur ? "bg-[#E87930]" : "bg-[#369E8A]";

    return (
        <NavLink
            to={link}
            className={`p-3 w-[32px] h-[32px] flex items-center justify-center rounded-md text-white ${backgroundColor}`}
        >
            <span className="">{icon}</span>
        </NavLink>
    );
};

export default ReseauSocial;
