import React from "react";

function FiltreProjet({ activeTab, setActiveTab, onChange }) {
  return (
    <div>
      <ul className="flex flex-wrap p-0 mb-4 m-0">
        <li
          className={`px-4 py-2 cursor-pointer ${activeTab === 0 ? "bg-[#E87930] rounded-lg text-white" : "text-black"
            }`}
          onClick={() => {
            setActiveTab(0);
            if (onChange) {
              onChange(0);
            }
          }}
        >
          Tous les projets
        </li>
        <li
          className={`px-4 py-2 cursor-pointer ${activeTab === 4 ? "bg-[#E87930] rounded-lg text-white" : "text-black"
            }`}
          onClick={() => {
            setActiveTab(4);
            if (onChange) {
              onChange(4);
            }
          }}
        >
          Suspendu
        </li>
        <li
          className={`px-4 py-2 cursor-pointer ${activeTab === 1 ? "bg-[#E87930] rounded-lg text-white" : "text-black"
            }`}
          onClick={() => {
            setActiveTab(1);
            if (onChange) {
              onChange(1);
            }
          }}
        >
          En cours
        </li>
        <li
          className={`px-4 py-2 cursor-pointer ${activeTab === 3 ? "bg-[#E87930] rounded-lg text-white" : "text-black"
            }`}
          onClick={() => {
            setActiveTab(3);
            if (onChange) {
              onChange(3);
            }
          }}
        >
          En attente
        </li>
        <li
          className={`px-4 py-2 cursor-pointer ${activeTab === 5 ? "bg-[#E87930] rounded-lg text-white" : "text-black"
            }`}
          onClick={() => {
            setActiveTab(5);
            if (onChange) {
              onChange(5);
            }
          }}
        >
          Terminé
        </li>
        <li
          className={`px-4 py-2 cursor-pointer ${activeTab === 2 ? "bg-[#E87930] rounded-lg text-white" : "text-black"
            }`}
          onClick={() => {
            setActiveTab(2);
            if (onChange) {
              onChange(2);
            }
          }}
        >
          Rejeté
        </li>
      </ul>
    </div>
  );
}

export default FiltreProjet;