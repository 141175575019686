import React, { useState, useRef, useEffect } from "react";
import { BsCloudDownload } from "react-icons/bs";

const DragDropFiles = ({ name, register, required, setValue }) => {
  const [files, setFiles] = useState(null);
  const inputRef = useRef();

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setFiles(event.dataTransfer.files);
  };

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  useEffect(() => {
    if (files) {
      setValue(name, files); // Définir la valeur du champ de fichier dans le formulaire
    }
  }, [files, name, setValue]);

  if (files) {
    return (
      <div className="uploads">
        <div className="w-full px-3 py-5 border border-dashed border-[#CACED8] rounded bg-[#F8F8FF]">
          <div className="flex justify-center flex-col items-center gap-3">
            <BsCloudDownload />
            <ul>
              {Array.from(files).map((file, idx) => (
                <li className="list-none" key={idx}>
                  {file.name}
                </li>
              ))}
            </ul>
            <p className="text-[9px] text-[#676767] font-[400] pt-0">
              Formats pris en charge : Pdf, DOC, PPTX, XLS, XLSX
            </p>
            <div className="flex justify-center gap-3">
              <button className="text-[var(--secondary-color)] border border-[var(--secondary-color)]
              py-2 px-5 rounded-lg" onClick={() => setFiles(null)}>Annuler</button>
              <button className="text-[var(--secondary-color)] border border-[var(--secondary-color)]
              py-2 px-5 rounded-lg hidden" onClick={() => inputRef.current && inputRef.current.click()}>Ajouter</button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
      <div className="w-full px-3 py-5 border border-dashed border-[#CACED8] rounded bg-[#F8F8FF]">
        <div className="flex justify-center flex-col items-center gap-3 w-full">
          <BsCloudDownload />
          <p className="text-[12px] font-[700]">
            Glisser-déposer des fichiers ou{" "}
            <button type="button" onClick={() => inputRef.current && inputRef.current.click()} className="text-[var(--secondary-color)]">
              <u>Parcourir</u>
            </button>
          </p>
          <p className="text-[9px] text-[#676767] font-[400] pt-0">
            Formats pris en charge : Pdf, DOC, PPTX, XLS, XLSX
          </p>
        </div>
      </div>
      <input
        type="file"
        multiple
        name={name}
        {...register(name, { required })}
        onChange={handleFileChange}
        hidden
        accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx"
        ref={inputRef}
      />
    </div>
  );
};

export default DragDropFiles;
