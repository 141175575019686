// src/Dashboard.js
import React, { useState } from "react";
import Titre from "components/Titre";
import PieChart from "components/Chart";
import { useGetProjetStatistiqueByEntrepreneurQuery } from "actions/projets/queries";
import useCurrentUser from "hooks/useCurrentUser";
import { mois } from "utils/date";
// Données pour le tableau
import { LiaSearchSolid } from "react-icons/lia";
import TableComponent from "components/TableComponents";
import Loader2 from "components/Loader/Loader2";
import { useGetProjetSouscritByEntrepreneurQuery } from "actions/projets/queries";
import styled from "styled-components";

const StyledHeader =styled.div `
th {
  color:var(--secondary-color);
  }
 td{
  padding:13px
 }   
`
function Dashboard() {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  // Les données qui concernent les diagrammes
  const { data: projetStatistiqueInvestByEntrepreneur } =
    useGetProjetStatistiqueByEntrepreneurQuery(id);
  const montantTotal =
    projetStatistiqueInvestByEntrepreneur?.montant_total_tous_projets;
  const [selectedMonth, setSelectedMonth] = useState("");
  const valeur = projetStatistiqueInvestByEntrepreneur?.projets;
  const valeurLine =
    projetStatistiqueInvestByEntrepreneur?.investissements_mensuels;
  const dataValues = valeur?.map((projet) => projet?.id);
  const dataProjet = valeur?.map((projet) => projet?.title);
  const lineLabel = valeurLine?.map((projet) => mois(projet?.month));
  const lineValueLabel = valeurLine?.map((projet) => projet?.total);
  const pourcentage = valeur?.map((projet) => projet?.total_invested);
  const filteredByMonth = lineLabel?.filter((item) => {
    return selectedMonth === "" || item?.month === selectedMonth;
  });

  // Les données qui concernent le tableau
  const { data: projectSouscritByEntrepreneur, isLoading } =
    useGetProjetSouscritByEntrepreneurQuery(id);
  const filteredProject = projectSouscritByEntrepreneur?.results || [];
  console.log({ filteredProject });
  const [search, setSearch] = useState("");
  const [selectedSecteur, setSelectedSecteur] = useState("");
  const [selectedProjet, setSelectedProjet] = useState("");

  const columns = [
    { id: 1, header: "Prénom", accessor: "prenom" },
    { id: 2, header: "Nom", accessor: "nom" },
    { id: 3, header: "Secteurs d'Activité", accessor: "SecteursAct" },
    { id: 4, header: "Début", accessor: "debut" },
    { id: 5, header: "Fin", accessor: "fin" },
  ];
  const data = filteredProject?.map((projetSouscrit) => ({
    prenom: projetSouscrit?.user?.prenom || "Non défini",
    nom: `${projetSouscrit?.user?.nom || "Non défini"}`,
    SecteursAct: projetSouscrit?.projet?.secteur?.[0]?.name || "Non défini",
    debut: `${projetSouscrit?.projet?.date_fin || "Non défini"}`,
    fin: projetSouscrit?.projet?.date || "Non défini",
  }));

  const filteredData = data?.filter((row) => {
    const secteurMatches =
      selectedSecteur === "" || row?.SecteursAct === selectedSecteur;
    const projetMatches = selectedProjet === "" || row?.nom === selectedProjet;

    return (
      (row?.prenom &&
        row?.prenom.toLowerCase().includes(search.toLowerCase())) ||
      (row?.nom &&
        row?.nom.toLowerCase().includes(search.toLowerCase()) &&
        secteurMatches &&
        projetMatches)
    );
  });

  const secteursUniques = filteredProject?.reduce((acc, projetSouscrit) => {
    const secteurName = projetSouscrit?.projet?.secteur?.[0]?.name;
    if (secteurName && !acc.includes(secteurName)) {
      acc.push(secteurName);
    }
    return acc;
  }, []);
  return (
    <>
      <Titre titre={"Dashboard"} bold={true} />
      <div className="grid grid-cols-2 md:grid-cols-1 w-100 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 ">
        <div className="grid grid-cols 1/2 sm:grid-cols-1">
          <PieChart
            titleChart={"Evolution du levée de fonds"}
            lineLabels={filteredByMonth}
            fontSize={"40px"}
            type={"Line"}
            valueLine={lineValueLabel}
            widthChart="100%"
            triByMonth={valeurLine}
            valueTri={selectedMonth}
            onChangeTri={(e) => setSelectedMonth(e.target.value)}
            flexTotal="hidden"
          />
        </div>
        <div>
          <PieChart
            titleChart={"Montant total des levée de fonds"}
            labels={dataProjet}
            numberColor={"var(--secondary-color)"}
            showPercentages={true}
            total={`${montantTotal || "0"} FCFA`}
            dataValues={dataValues}
            percentages={pourcentage}
            money="FCFA"
            flexDirection="column"
            display={"flex"}
          />
        </div>
        <div className="">
          <PieChart
            titleChart={"Profit de l’entreprise"}
            /* labels={dataProjet} */
            numberColor={"var(--secondary-color)"}
            total={`${montantTotal || "0"} FCFA`}
            dataValues={dataValues}
            flexDirection="column"
          />
        </div>
      </div>
      <div className="mt-10">
        <div className="flex justify-between items-center sm:flex-col">
          <Titre titre={"Projets Intéressés"} bold={true} size={"22px"} />
          <div className="flex items-center py-2 ps-3 mx-3 gap-4 border w-[40%] sm:w-full bg-white  rounded-md border-[var(--secondary-color)]">
            <div className="mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-[var(--secondary-color)]"
              >
                Projets
              </label>
              <select
                name="projet"
                value={selectedProjet}
                onChange={(e) => setSelectedProjet(e.target.value)}
                className="form control w-full px-2 py-2 border border-[var(--secondary-color)] rounded outline-0 text-[var(--secondary-color)]"
              >
                <option value="">Sélectionner un projet</option>
                {filteredProject?.map((projetSouscrit) => (
                  <option
                    key={projetSouscrit?.projet?.id}
                    value={projetSouscrit?.projet?.title}
                  >
                    {projetSouscrit?.projet?.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-[var(--secondary-color)]"
              >
                Secteurs
              </label>
              <select
                name="etapeA"
                value={selectedSecteur}
                onChange={(e) => setSelectedSecteur(e.target.value)}
                className="form control w-full px-2 py-2 border border-[var(--secondary-color)] rounded outline-0 text-[var(--secondary-color)]"
              >
                <option value="">Sélectionner un secteur</option>
                {secteursUniques?.map((secteur, index) => (
                  <option key={index.id} value={secteur}>
                    {secteur}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 p-3">
          <div className="flex items-center w-full relative">
            <span className="absolute ms-[-1px] p-3">
              <LiaSearchSolid className="text-[var(--secondary-color)] text-2xl" />
            </span>
            <input
              type="text"
              name="name"
              className="w-full px-12 py-2 border border-[var(--secondary-color)] rounded-full outline-0 text-[var(--septanaryBorderColor)]"
              placeholder="Rechercher un projet"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        {isLoading ? (
          <Loader2 />
        ) : filteredData && filteredData.length > 0 ? (
          <StyledHeader>
          <TableComponent
            data={filteredData}
            columns={columns}
            bgColor={"#F3F6F9"}
            color={"#6A707E"}
          />
          </StyledHeader>
        ) : (
          <div className="text-xl text-red-500 pb-8">Pas de données</div>
        )}
      </div>
    </>
  );
}

export default Dashboard;