import React, { useState } from "react";
import Profile1 from "../assets/Images/avatar.webp"
import { API_STORAGE } from "../routes/api/endpoints"

const TwoButtons = ({ onClick }) => {
  return (
    <div className="flex justify-evenly sm:justify-evenly mb-2">
      <div className="flex items-center">
        <button
          onClick={onClick}
          className={"text-white rounded-md px-4 py-2 bg-[#369E8A] w-[190px] h-[46px] sm:w-[130px] sm:h-[40px] md:w-[130px] md:h-[46px] lg:w-[140px] lg:h-[46px] 2xl:w-[225px] 2xl:h-[46px]"}
        >
          Valider
        </button>
      </div>
      <div className="flex items-center">
        <button
          onClick={onClick}
          className={"text-white rounded-md px-4 py-2 bg-[#FF0000] w-[190px] h-[46px] sm:w-[130px] sm:h-[40px] md:w-[130px] md:h-[46px] lg:w-[140px] lg:h-[46px] 2xl:w-[225px] 2xl:h-[46px]"}
        >
          Rejeter
        </button>
      </div>
    </div>
  );
};

export default TwoButtons;

const IconWithDropdown = ({
  data
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative md:left-[-25px]">
      <div className="rounded-full flex items-center cursor-pointer">
        {data && data.slice(0, 5).map((subscriber, index) => (
          <img
            key={subscriber.user.id}
            src={subscriber.user.avatar ? API_STORAGE + subscriber.user.avatar : Profile1}
            alt="Souscrit avatar"
            className={`w-[41px] h-[41px] object-cover rounded-full ${index !== 0 && "-ml-3"}`}
          />
        ))}
        <div
          onClick={handleToggleDropdown}
          className="border rounded-full bg-orange-400 w-[41px] h-[41px] p-2 object-cover flex items-center justify-center cursor-pointer -ml-3"
        >
          {data?.length || "0"}+
        </div>
      </div>
      {isOpen && (
        <div className="absolute w-[24rem] top-12 left-[-20rem] md:left-[-18rem] sm:w-[18rem] sm:left-[-0.5rem] sm:text-sm bg-white shadow-lg rounded-md p-4 z-10">
          <div className="flex justify-between border-b pb-2 mb-2">
            <div className="left">
              <span className="font-bold">Tous</span>
              <span className="ml-2">({data?.length})</span>
            </div>
            <div className="right">
              <button className="font-bold text-sm" onClick={handleToggleDropdown}>X</button>
            </div>
          </div>
          <ul className="border-b">
            {data && data.map((subscriber) => (
              <li key={subscriber?.user?.id} className="flex items-center gap-2 mb-2">
                <img src={subscriber?.user?.avatar ? API_STORAGE + subscriber?.user?.avatar : Profile1} alt="Une image" className="w-8 h-8 object-cover rounded-full" />
                <span>{subscriber?.user?.prenom} {subscriber?.user?.nom}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export { TwoButtons, IconWithDropdown };
