import SpliTemplateScreen from "layouts";
import NavbarComponent from "layouts/navbar/NavbarComponent";
import SidebarComponent from "layouts/sidebar/SidebarComponent";
import React from "react";
import { Outlet } from "react-router-dom";
import { linksEntrepreneur } from "routes/navigation/sidebarLinks";

const EntrepreneurShell = () => {
  return <div>
     <SpliTemplateScreen>
            <NavbarComponent />
            <SidebarComponent
                bgColor={"#fff"}
                activeLinkColor={"#fff"}
                activeLinkBgColor="var(--secondary-color)"
                linkColor="black"
                colorOnHover={"black"}
                resourceData={linksEntrepreneur}
            />
            <Outlet />
        </SpliTemplateScreen>
  </div>;
};

export default EntrepreneurShell;
