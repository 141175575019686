import React from "react";
import Titre from "./Titre";
import { FaEdit } from "react-icons/fa";
import { useGetIncubateurIdQuery } from "actions/auth/queries"
import { ToastContainer } from "react-toastify";
import { FaCamera } from "react-icons/fa";
import useCurrentUser from "hooks/useCurrentUser";
import { API_STORAGE } from "routes/api/endpoints"
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ReseauSocial from "./ReseauSocial";
import ModalAjout from "layouts/modal/ModalAjout"
import ProfileUpdateUser from "./ProfileUpdateUser";

const ProfileUpdate = ({ backgroundIcon }) => {
    const currentUser = useCurrentUser();
    const id = currentUser?.id;
    const { data: incubateurData } = useGetIncubateurIdQuery(id);
  const isIncubateur = currentUser?.user_type === "incubateur";
  const borderColor = isIncubateur ? "border-[#E87930]" : "border-[#369E8A]";
  const backgroundColor = isIncubateur ? "bg-[#E87930]" : "bg-[#369E8A]";
  const userType = currentUser?.user_type;
    const [showModal, setShowModal] = React.useState(false);
    const ReseauSociaux = [
        {
          id: 1,
          icon: <FaFacebookF />,
          link: "#",
        },
        {
          id: 2,
          icon: <FaLinkedinIn />,
          link: "#",
        },
        {
          id: 3,
          icon: <FaXTwitter />,
          link: "#",
        },
      ];

    return (
        <>
            <div className={`relative min-w-36 max-w-36 min-h-36 max-h-36 rounded-full border-[6px] ${borderColor} flex justify-center items-center shadow-lg`}>
        <img src={ API_STORAGE + currentUser?.avatar } alt={"Une image"} className="w-full h-full rounded-full scale-90" />
        <FaCamera
          size={30}
          color="white"
          className={`absolute top-[7.5rem] right-[8px] transform -translate-y-1/2
          transition-opacity duration-300 ${backgroundColor} rounded-full p-1`}
        />
      </div>
            <Titre titre={ userType === "incubateur" ? incubateurData?.nom_incubateur : `${currentUser?.prenom} ${currentUser?.nom}`}
            size="24px" bold={true} mB="0rem" />
            <div className="flex items-center gap-5">
                <div>
                    <p className="text-gray-500 text-center text-xs md:text-lg mt-[-10px] p-2 flex-grow">
                    {currentUser?.description || "Pas de desciption pour le moment"}
                    </p>
                </div>
                <div>
                    <FaEdit
                        className="text-gray-500 cursor-pointer"
                        size={20}
                        onClick={() => setShowModal(true)}
                    />
                </div>
            </div>
            <div className="flex mt-3 space-x-3">
            {
              ReseauSociaux.map((item) => (
                <ReseauSocial
                  key={item.id}
                  icon={item.icon}
                  link={item.link}
                  backgroundIcon={backgroundIcon}
                />
              ))}
          </div>
            <ModalAjout titre="Modification du profil"
              onClose={() => setShowModal(false)}
              showModal={showModal}
            >
              <ProfileUpdateUser onClick={() => setShowModal(false)}/>
            </ModalAjout>
            <ToastContainer className={"mt-10"}/>
        </>
    );
};

export default ProfileUpdate;
