import React, { useEffect, useState } from "react";
import { IoMdNotifications } from "react-icons/io";
import { useSelector } from "react-redux";
import { getUser } from "../redux/features/userSliceWithTokenValidation";
import { useNotificationQuery } from "../actions/souscrire/queries";
import { store } from "react-notifications-component";
import ModalNotification from "layouts/modal/Modal";
import ListeNotification from "./ListeNotification";

function Notification() {
  const currentUser = useSelector(getUser);
  const id = currentUser.data.id;
  const userType = currentUser.data.userType;
  const { data: notificationData, refetch, isLoading } = useNotificationQuery(id);
  const notificationToday = notificationData?.subscribers_today || [];
  const notificationTotal = notificationData?.subscribers_total || [];

  useEffect(() => {
    const interval = setInterval(() => {
      refetch().then((newData) => {
        if (newData.data && newData.data.newNotifications) {
          newData.data.newNotifications.forEach((notification) => {
            if (userType === "incubateur") {
              store.addNotification({
                title: "Nouvelle souscription",
                message: notification.message,
                type: "info",
                container: "top-right",
                dismiss: {
                  duration: 5000,
                  onScreen: true,
                },
              });
            }
          });
        }
      });
    }, 60000); // Vérifier toutes les minutes

    return () => clearInterval(interval);
  }, [refetch, userType]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // const sourceData = subscribers_total || subscribers_today;
  return (
    <>
      <div className="relative sm:top-[2px]">
        <button onClick={toggleModal}>
          <IoMdNotifications className="text-white text-3xl sm:text-2xl" />
          <span className="absolute top-[-4px] left-[16px] bg-[var(--quinaryBorderColor)]
          text-white flex flex-col justify-center rounded-full w-[25px] h-[25px] text-center text-xs font-bold sm:ml-[-30px]">
            {notificationData?.subscribers_today_count}
          </span>
        </button>
      </div>
      {isModalOpen && (
        <ModalNotification onOpenModal={() => setIsModalOpen(false)} onCloseModal={toggleModal}
        titre={"Notifications"} marginTop={"mt-6 , justify-end"}>
          <ListeNotification
            data={notificationToday}
            Loading={isLoading}
            interesser={"Intéressés de nos projets du jour"}
          />
          <ListeNotification
            data={notificationTotal}
            Loading={isLoading}
            interesser={"Intéressés de nos projets"}
          />
        </ModalNotification>
      )}
    </>
  );
}

export default Notification;
