const formatNumber = (value) => {
  if (value === undefined || value === null) {
    return "0"; // or return an empty string, 'N/A', etc., depending on your needs
  }
  if (typeof value !== "string") {
    value = value.toString();
  }
  return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export default formatNumber;
