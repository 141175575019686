import React from "react";
import {
  ACCEUIL_PATH,
  INCUBATEUR_PATH,
  INVESTISSEUR_PATH,
  ENTREPRENEUR_PATH,
  PROJETS_PATH,
  DETAILS_PATH,
  LISTES_PORTEURS_PROJET_PATH,
  PROJETS_FINANCES,
  DETAIL_PROJETS_FINANCES,
  LEVER_FONDS,
  PROFIL_INCUBATEUR,
  RAPPORT,
  PROJET_INVESTISSEUR,
  PROJET_DETAIL_INVESTISSEUR,
  RAPPORT_INVESTISSEUR,
  PROJET_FINANCER_INVESTISSEUR,
  PROJET_FINANCER_DETAIL,
  PROFIL_Invest,
  PROJET_PATH_ENTREPRENEUR,
  PROJET_PATH_ENTREPRENEUR_Detail,
  RAPPORTEntrepreneur,
  PROFIL_Entrepreneur
} from "../navigationPaths";
import RequireAuth from "layouts/RequireAuth";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import IncubateurShell from "layouts/AppShells/IncubateurShell";
import InvestisseurShell from "layouts/AppShells/InvestisseurShell";
import Dashboard from "pages/PartieIncubateur/Dashboard/Dashboard";
import Projets from "pages/PartieIncubateur/Projets/Projets";
import DetailProjet from "pages/PartieIncubateur/Projets/DetailProjet";
import PorteursProjets from "pages/PartieIncubateur/PorteursProjets/PorteursProjets";
import ProjetsFinances from "pages/PartieIncubateur/ProjetsFinances/ProjetsFinances";
import DetailProjetFinance from "pages/PartieIncubateur/ProjetsFinances/DetailProjetFinance";
import LeverFond from "pages/PartieIncubateur/LeverFond/LeverFond";
import Profil from "pages/PartieIncubateur/Profil/Profil";
import Rapport from "pages/PartieIncubateur/Rapport/Rapport";
import DashboardInvestisseur from "pages/PartieInvestisseur/Dashboard/Dashboard";
import ProjetInvestisseur from "pages/PartieInvestisseur/Projets/Projet"
import DetailProjetInvestisseur from "pages/PartieInvestisseur/Projets/DetailProjet";
import RapportInvest from "pages/PartieInvestisseur/Rapport/Rapport"
import { Navigate } from "react-router-dom";
import ProjetFinancerInvest from "pages/PartieInvestisseur/Projetsfinancer/ProjetFinancerInvest";
import DetailProjetFinancer from "pages/PartieInvestisseur/Projetsfinancer/DetailProjetFinancer";
import ProfilInvest from "pages/PartieInvestisseur/Profil/ProfilInvest";
import EntrepreneurShell from "layouts/AppShells/EntrepreneurShell";
import DashboardEntrepreneur from "pages/PartieEntrepreneur/Dashboard/DashboardEntrepreneur"
import ProjetEntrepreneur from "pages/PartieEntrepreneur/Projets/ProjetEntrepreneur";
import DetailProjetEntrepreneur from "pages/PartieEntrepreneur/Projets/DetailProjetEntrepreneur"
import RapportEntrepreneur from "pages/PartieEntrepreneur/Rapport/RapportEntrepreneur";
import ProfilEntrepreneur from "pages/PartieEntrepreneur/Profil/ProfilEntrepreneur";

export const privateRoutesIncubateur = [
  {
    path: "*",
    element: <Navigate to={"/incubateur"} />,
  },
  {
    path: ACCEUIL_PATH,
    errorElement: <ErrorBoundary />,
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: <IncubateurShell />,
        children: [
          {
            path: INCUBATEUR_PATH,
            element: <Dashboard />,
          },
          {
            path: PROJETS_PATH,
            element: <Projets />,
          },
          {
            path: DETAILS_PATH,
            element: <DetailProjet />,
          },
          {
            path: LISTES_PORTEURS_PROJET_PATH,
            element: <PorteursProjets />,
          },
          {
            path: PROJETS_FINANCES,
            element: <ProjetsFinances />,
          },
          {
            path: DETAIL_PROJETS_FINANCES,
            element: <DetailProjetFinance />,
          },
          {
            path: LEVER_FONDS,
            element: <LeverFond />,
          },
          {
            path: PROFIL_INCUBATEUR,
            element: <Profil />,
          },
          {
            path: RAPPORT,
            element: <Rapport />,
          },
        ],
      },
    ],
  },
];

export const privateRoutesInvestisseur = [
  {
    path: "*",
    element: <Navigate to={"/investisseur"} />,
  },
  {
    path: ACCEUIL_PATH,
    errorElement: <ErrorBoundary />,
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: <InvestisseurShell />,
        children: [
          {
            path: INVESTISSEUR_PATH,
            element: <DashboardInvestisseur/>
          },
          {
            path: PROJET_INVESTISSEUR,
            element: <ProjetInvestisseur/>
          },
          {
            path: PROJET_DETAIL_INVESTISSEUR,
            element: <DetailProjetInvestisseur/>
          },
          {
            path: PROJET_FINANCER_INVESTISSEUR,
            element: <ProjetFinancerInvest/>
          },
          {
            path: PROJET_FINANCER_DETAIL,
            element: <DetailProjetFinancer/>
          },
          {
            path: RAPPORT_INVESTISSEUR,
            element: <RapportInvest/>
          },
          {
            path: PROFIL_Invest,
            element: <ProfilInvest/>
          },
        ],
      },
    ],
  },
];

export const privateRoutesEntrepreneur = [
  {
    path: "*",
    element: <Navigate to={"/dashboard_entrepreneur"} />,
  },
  {
    path: ACCEUIL_PATH,
    errorElement: <ErrorBoundary />,
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: <EntrepreneurShell/>,
        children: [
          {
            path: ENTREPRENEUR_PATH,
            element: <DashboardEntrepreneur/>
          },
          {
            path: PROJET_PATH_ENTREPRENEUR,
            element: <ProjetEntrepreneur/>
          },
          {
            path: PROJET_PATH_ENTREPRENEUR_Detail,
            RAPPORTEntrepreneur,
            PROFIL_Entrepreneur,
            element: <DetailProjetEntrepreneur/>
          },
          {
            path: RAPPORTEntrepreneur,
            element: <RapportEntrepreneur/>
          },
          {
            path: PROFIL_Entrepreneur,
            element: <ProfilEntrepreneur/>
          }
        ]
      },
    ],
  },
];

export const privateRoutesAdmin = [
  {
    path: "*",
    element: <Navigate to={"/admin"} />,
  },
  {
    path: ACCEUIL_PATH,
    errorElement: <ErrorBoundary />,
    element: <RequireAuth />,
    children: [
      {
        path: "",
        element: (
            <p>Coucou Admin</p>),
      },
    ],
  },
];