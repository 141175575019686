import React from "react";
import Roots from "./routes/Routes";
import "./style/style.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div>
      <Roots />
    </div>
  );
}

export default App;
