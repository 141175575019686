import { useState, useEffect } from "react";
import axios from "axios";

const useCountries = (userCountryLabel) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const countriesData = response.data.map((country) => {
          const frenchName = country.translations?.fra?.common || country.name.common;
          return {
            label: frenchName,
            value: country.cca2,
            callingCode: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : ""),
          };
        });
        setCountries(countriesData);
        const userCountry = countriesData.find(
          (country) => country.label === userCountryLabel
        );
        setSelectedCountry(userCountry || null);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, [userCountryLabel]);

  return {
    countries,
    selectedCountry,
    setSelectedCountry,
  };
};

export default useCountries;