import React, { useState } from "react";
import ProjetDetail from "../../../components/ProjetDetail";
import { MdOutlineWatchLater } from "react-icons/md";
import IconInvestissement from "../../../assets/Images/investment (5) 1.png";
import ArrowScope from "../../../assets/Images/Vector.png";
import { API_STORAGE } from "../../../routes/api/endpoints";
import { useParams } from "react-router-dom";
import { useGetProjetsQuery } from "../../../actions/projets/queries";
import { usePostProjectIdMutation } from "../../../actions/projets/mutations";
import { useGetSubscriberIdQuery } from "../../../actions/souscrire/queries";
import { ToastContainer, toast } from "react-toastify";
import { useRefresh } from "context/RefreshContext";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import ModalAjout from "layouts/modal/ModalAjout";
import MotifRejet from "components/MotifRejet";
import MotifSuspension from "components/MotifSuspension";
const DetailProjet = () => {
  const { id } = useParams();
  const {
    data: projectsData,
    refetch: refetchProjects,
    isLoading,
  } = useGetProjetsQuery(id);
  console.log({ projectsData });

  const [postProject, { isLoading: postLoading }] = usePostProjectIdMutation();
  const { triggerRefresh } = useRefresh();
  const [currentAction, setCurrentAction] = useState(null);
  const [showModal, setShowModal] = React.useState(false);
  const [showModalSuspension, setShowModalSuspension] = React.useState(false);
  const Span = [
    {
      icon: <MdOutlineWatchLater color="#F68B1E" size={20} />,
      title: "Date du début",
      subtitle: projectsData?.data_lancement || "Non défini",
      subtitleClass: "font-light",
    },
    {
      icon: <MdOutlineWatchLater color="#F68B1E" size={20} />,
      title: "Date d'échéance",
      subtitle: projectsData?.data_lancement || "Non défini",
      subtitleClass: "font-light",
    },
    {
      icon: <img src={IconInvestissement} alt="Icon Investissement" />,
      title: "Besoin en investissement",
      subtitle: `FCFA ${projectsData?.montant || "0"}`,
      subtitleClass: "font-bold text-orange-400",
    },
    {
      icon: <img src={ArrowScope} alt="Icon Investissement" />,
      title: "Montant atteint",
      subtitle: `FCFA ${projectsData?.montant_part || "0"}`,
      subtitleClass: "font-bold text-green-400",
    },
  ];
  const { id: projectId } = useParams();
  const { data: souscrire } = useGetSubscriberIdQuery(projectId);
  // console.log({ souscrire });
  const ReseauSociaux = [
    {
      id: 1,
      icon: <FaFacebookF />,
      link: "#",
    },
    {
      id: 2,
      icon: <FaLinkedinIn />,
      link: "#",
    },
    {
      id: 3,
      icon: <FaXTwitter />,
      link: "#",
    },
  ];
  const filteredSubscribers = Array.isArray(souscrire?.results) ? souscrire?.results?.filter(
    (subscriber) => subscriber?.projet.id.toString() === projectId
  ) : [];
  const handleAction = async (item, action, motifs) => {
    let actionText;
    const body = { statut: "" };
    switch (action) {
      case "valider":
        actionText = "validé";
        body.statut = "valider";
        break;
      case "rejeter":
        actionText = "Rejeté";
        body.statut = "rejeter";
        body.motif_rejet = motifs;
        break;
      case "suspendu":
        actionText = "suspendu";
        body.statut = "suspendu";
        body.motif_suspension = motifs;
        break;
      default:
        toast.error("Action inconnue");
        return;
    }
    try {
      const { error } = await postProject({ id: item?.id, body });
      if (error) {
        throw new Error(error.message);
      }
      toast.success(`Ce projet a été ${actionText} avec succès`);
      triggerRefresh();
      refetchProjects();
    } catch (error) {
      toast.error(
        `Oups ! Une erreur est survenue lors de ${actionText} du projet.`
      );
      console.log("Erreur est survenue", error);
    }
  };
  const handleSuspendre = () => {
    setShowModalSuspension(true);
    setCurrentAction("suspendu");
  };
  const handleRejeter = () => {
    setShowModal(true);
    setCurrentAction("rejeter");
  };
  const submitMotif = (motifs) => {
    console.log("Motifs sélectionnés:", motifs);
    handleAction(projectsData, currentAction, motifs);
    setShowModal(false);
    setShowModalSuspension(false)
  };

  return (
    <div>
      <ToastContainer className={"mt-10"} />
      <ModalAjout
        onClose={() => setShowModal(false)}
        titre={"Les raisons de la rejette du projet"}
        showModal={showModal}
      >
        <MotifRejet isLoading={postLoading}
          onSubmit={submitMotif} onClickAnnul={() => setShowModal(false)} />
      </ModalAjout>
      <ModalAjout
        onClose={() => setShowModalSuspension(false)}
        titre={"Raisons de la suspension"}
        showModal={showModalSuspension}
      >
        <MotifSuspension isLoading={postLoading}
          onSubmit={submitMotif} onClickAnnul={() => setShowModalSuspension(false)} />
      </ModalAjout>
      <ProjetDetail
        Link={"/projets"}
        video={API_STORAGE + projectsData?.video}
        title={projectsData?.title}
        description={projectsData?.description}
        data={Span}
        prenom={projectsData?.user?.prenom}
        nom={projectsData?.user?.nom}
        souscrireData={filteredSubscribers}
        disabledValider={projectsData?.statut === "valider"}
        disabledRejeter={projectsData?.statut === "rejeter"}
        disabledSuspendre={projectsData?.statut === "suspendu"}
        projet={projectsData}
        onClick1={() => handleAction(projectsData, "valider")}
        onClick2={handleRejeter}
        onClick3={handleSuspendre}
        hidden={"hidden"}
        isLoading={isLoading}
        background={"var(--backgroundOrange)"}
        reseau={ReseauSociaux}
      />
    </div>
  );
};

export default DetailProjet;