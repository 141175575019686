import React, { useState } from "react";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend as ChartLegend,
  LineElement,
  PointElement,
  CategoryScale, // axe x
  LinearScale, // axe y
  Title,
} from "chart.js";
import Legend from "./Legend";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import { mois } from "utils/date";
import formatNumber from "utils/FormatNumber";

ChartJS.register(
  ArcElement,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Title,
  Tooltip,
  ChartLegend,
  ChartDataLabels
);

const generateRandomColors = (numColors) => {
  const colors = new Set();
  while (colors.size < numColors) {
    colors.add(`hsl(${Math.random() * 360}, 100%, 75%)`);
  }
  return Array.from(colors);
};

const PieChart = ({
  titleChart,
  montantInvesti,
  labels = [],
  showPercentages = false,
  numberColor,
  colors = [],
  total,
  percentages,
  dataValues,
  cutout,
  triByMonth,
  fontSize,
  Labelpercentages,
  display,
  type = "Pie1",
  lineLabels = [],
  valueLine = [],
  money,
  widthChart,
  onChangeTri,
  valueTri,
  flexTotal,
  flexTotalInvesti
}) => {
  if (dataValues?.length !== colors?.length) {
    colors = generateRandomColors(dataValues?.length);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        data: dataValues,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const lineChartData = {
    labels: lineLabels,
    datasets: [
      {
        data: valueLine,
        borderColor: valueLine.map((value) =>
          value !== 0 ? "red" : "#2B7E6E"
        ),
        borderWidth: 2,
        fill: true,
        tension: 0.4,
        pointBackgroundColor: valueLine.map((value) =>
          value !== 0 ? "red" : "#2B7E6E"
        ),
        width: "100px",
        pointRadius: 5, // Pour ne pas afficher les points
        datalabels: {
          display: false, // Désactiver les labels de données
        },
      },
    ],
  };

  let options = {};
  if (type === "Pie2") {
    options = {
      cutout: cutout ? cutout : "70%",
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          formatter: (context) => {
            const percentage = Labelpercentages[context.dataIndex];
            return percentage;
          },
          color: "#fff",
          font: {
            weight: "bold",
            size: "21px",
          },
        },
      },
    };
  } else if (type === "Line") {
    options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          min: Math.min(...valueLine), // Valeur minimale de votre tableau
          max: Math.max(...valueLine), // Valeur maximale de votre tableau
          ticks: {
            font: (context) => {
              const width = context.chart.width;
              if (width < 700) {
                return {
                  size: 9, // Taille "sm"
                };
              } else {
                return {
                  size: 5, // Taille normale
                };
              }
            },
          },
        },
        x: {
          ticks: {
            font: (context) => {
              const width = context.chart.width;
              if (width < 600) {
                return {
                  size: 9, // Taille "sm"
                };
              } else {
                return {
                  size: 5, // Taille normale
                };
              }
            },
          },
        },
      },
    };
  } else {
    options = {
      cutout: cutout ? cutout : "70%",
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: false,
        },
      },
    };
  }
  const [visibleLabels, setVisibleLabels] = useState(3);
  const handleShowMore = () => {
    setVisibleLabels((prevVisibleLabels) => prevVisibleLabels + labels.length);
  };
  const handleShowLess = () => {
    setVisibleLabels(3);
    if (labels.length <= 3) {
      setVisibleLabels(false);
    }
  };

  return (
    <div
      style={{
        background: "#fff",
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        padding: "20px",
        borderRadius: "10px",
        margin: "10px",
      }}
      className="flex flex-col justify-start min-h-70 max-h-70"
    >
      <div className="flex justify-between items-center sm:flex-col mb-4">
        <div
          className="titre lg:text-nowrap md:text-[14px] sm:text-nowrap text-[14px]  "
          style={{ color: "#369E8A", fontWeight: "bold" }}
        >
          <p>{titleChart}</p>
          <p className={`text-[16px] py-2 ${flexTotalInvesti ? flexTotalInvesti : "hidden"}`}>{formatNumber(montantInvesti || 0)}</p>
        </div>
        <div>
          {type === "Line" && (
            <div className="flex items-center sm:text-[8px]">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium text-[#696974]"
              >
                Trier par:
              </label>
              <select
                name="month"
                value={valueTri}
                onChange={onChangeTri}
                className="border-none bg-transparent focus:outline-none text-sm font-medium text-[#696974]"
              >
                <option value="">Mois</option>
                {triByMonth &&
                  triByMonth?.map((index) => (
                    <option key={index.id} value={index.id}>
                      {mois(index.month)}
                    </option>
                  ))}
              </select>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex gap-5 md:gap-2 md:flex-col lg:flex sm:flex-col gap-2 items-center"
        // style={{ flexDirection: flexDirection ? flexDirection : "row" }}
      >
        <div
          key={type}
          style={{
            position: "relative",
            width: widthChart ? widthChart : "50%",
          }}
        >
          {type === "Line" ? (
            <Line className="text-[12px] sm:font-bold" data={lineChartData} options={options} />
          ) : (
            <Doughnut data={data} options={options} />
          )}
          <div
            className={`sm:text-[12px] ${flexTotal}`}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: fontSize ? fontSize : "20px",
              fontWeight: "bold",
              color: numberColor || "",
              textAlign: "center",
            }}
          >
            {formatNumber(total || 0)}
          </div>
        </div>
        <div>
          <Legend
            labels={labels.slice(0, visibleLabels)}
            colors={colors.slice(0, visibleLabels)}
            percentages={percentages?.slice(0, visibleLabels)}
            showPercentages={showPercentages}
            display={display ? display : "flex"}
            money={money}
          />
          <div className="flex justify-center gap-2 mt-4">
            {visibleLabels < labels.length && visibleLabels >= 0 ? (
              <button
                onClick={handleShowMore}
                className="text-gray-500 underline bg-gray-400 px-4 rounded-lg"
              >
                <FaEye color="black" />
              </button>
            ) : (
              labels.length > 3 && (
                <button
                  onClick={handleShowLess}
                  className="text-gray-500 underline bg-gray-400 px-4 rounded-lg"
                >
                  <FaEyeSlash color="black" />
                </button>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PieChart;
