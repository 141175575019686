import { ApiMananger } from "actions/rtkquery";
import { GET_USERS, LIST_ENTREPRENEUR_BY_INCUBATEUR, GET_INCUBATEUR,
    GET_ENTREPRENEUR, GET_INCUBATEURID } from "routes/api/endpoints";

const userApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getUsers: build.query({
            query: () => GET_USERS,
            providesTags: ["INVALIDATE_USERS"]
        }),
        emailVerification: build.query({
            query: (token) => "API.USER_API.VERIFICATION_EMAIL(token)",
            providesTags: ["INVALIDATE_USERS"]
        }),
        getListEntrepreneurByIncubateur: build.query({
            query: (id) => `${LIST_ENTREPRENEUR_BY_INCUBATEUR}${id}`,
            providesTags: ["INVALIDATE_USERS"],
        }),
        getIncubateur: build.query({
            query: () => GET_INCUBATEUR,
            providesTags: ["INVALIDATE_USERS"],
        }),
        getEntrepreneur: build.query({
            query: (id) => `${GET_ENTREPRENEUR}${id}`,
            providesTags: ["INVALIDATE_USERS"],
        }),
        getIncubateurId: build.query({
            query: (id) => `${GET_INCUBATEURID}${id}`,
            providesTags: ["INVALIDATE_USERS"],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useEmailVerificationQuery,
    useGetListEntrepreneurByIncubateurQuery,
    useGetIncubateurQuery,
    useGetIncubateurIdQuery,
    useGetEntrepreneurQuery
} = userApi;