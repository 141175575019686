import React, { useEffect } from "react";
import Profils from "components/Profils";
import Titre from "components/Titre";
import { Controller, useForm } from "react-hook-form";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { useUpdateIncubateurIdMutation } from "actions/auth/mutations";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton";
import Select from "react-select";
import useCurrentUser from "hooks/useCurrentUser";
import { stadeDevelop } from "utils/PaysNState"
import { useGetProjetSecteurQuery } from "actions/projets/queries";
import { useGetIncubateurIdQuery } from "actions/auth/queries"
import useCountries from "hooks/useCountries";
import { useDispatch } from "react-redux";
import { updateUserSuccess } from "redux/features/userSliceWithTokenValidation"

const Profil = () => {
  const currentUser = useCurrentUser();
  const id = currentUser?.id;
  const { data: secteur } = useGetProjetSecteurQuery();
  const secteurs = secteur?.results;
  const { data: IncubateurData } = useGetIncubateurIdQuery(id);
  const { countries, selectedCountry, setSelectedCountry } = useCountries(IncubateurData?.pays);
  const dispatch = useDispatch();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "border": state.isFocused ? "1px solid var(--backgroundOrange)" : "1px solid #CACED8",
      "boxShadow": state.isFocused ? "0 0 0 2px var(--backgroundOrange)" : "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      "display": "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      "color": "#CACED8",
    }),
    singleValue: (provided) => ({
      ...provided,
      "color": "#333",
    }),
  };
  const [updateIncubateur, { isLoading: updateLoading }] = useUpdateIncubateurIdMutation();
  const { register, handleSubmit, setValue, control } = useForm();
  useEffect(() => {
    if (IncubateurData?.phone) {
      setValue("phone", IncubateurData.phone);
    }
  }, [IncubateurData, setValue]);
  useEffect(() => {
    if (selectedCountry) {
      setValue("pays", selectedCountry.label);
    }
  }, [selectedCountry, setValue]);
  const onSubmit = async (data) => {
  console.log({ data });
    const formData = new FormData();
    formData.append("nom_incubateur", data.nom_incubateur || "");
    formData.append("adresse", data.adresse || "");
    formData.append("email", data.email);
    formData.append("secteur", parseInt(data.secteur) || null);
    formData.append("etape_accompagment", data.etape_accompagment || "");
    formData.append("pays", data.pays || "");
    formData.append("ville", data.ville || "");
    formData.append("phone", data.phone || "");
    try {
      const response = await updateIncubateur({ id, body: formData });
      dispatch(updateUserSuccess(response));
      toast.success("Profil mis à jour avec succès !");
    } catch (err) {
      console.error("Échec de la mise à jour du profil:", err);
      toast.error("Échec de la mise à jour du profil.");
    }
  };

  return (
    <>
      <Titre titre="Mon Profil" bold={true} mB={false} />
      <Profils>
          <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col sm:flex-col sm:w-full"
      >
        <div className="flex items-center sm:flex-col gap-16 sm:gap-2">
          <div className="personnel w-1/2 sm:w-full">
            <Titre titre={"Personnel"} />
            <div className="mb-4">
                <label
                  htmlFor="nomIncubateur"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                 Nom de l'entreprise
                </label>
                <input
                  {...register("nom_incubateur")}
                  defaultValue={IncubateurData?.nom_incubateur}
                  placeholder="Volkeno"
                  type="text"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="adresse"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                 Adresse
                </label>
                <input
                        {...register("adresse")}
                        type="text"
                        defaultValue={IncubateurData?.adresse}
                        placeholder="HLM grand-yoff"
                        className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
                      />
              </div>
            <div className="mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Secteur d'activité
              </label>
              <select
                {...register("secteur")}
                defaultValue={IncubateurData?.secteur}
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
              >
                <option>--Sélectionner un secteur--</option>
                {
                    secteurs?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      )
                    })
                  }
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="etapeA"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Étape de l'accompagnement
              </label>
              <select
                {...register("etape_accompagment")}
                defaultValue={IncubateurData?.etape_accompagment}
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
              >
                <option>--Sélectionner une étape--</option>
                {
                      stadeDevelop?.map((item) => {
                        return (
                          <option key={item.id} value={item.value}>{item.name}</option>
                        )
                      })
                    }
              </select>
            </div>
          </div>
          <div className="contact w-1/2 sm:w-full">
            <Titre titre={"Contact"} />
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Adresse email
              </label>
              <input
                {...register("email")}
                defaultValue={IncubateurData?.email}
                type="email"
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
                placeholder="example@gmail.com"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="pays"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Pays
              </label>
              <Select
  defaultValue={IncubateurData?.pays}
  {...register("pays")}
  value={selectedCountry}
  onChange={(selectedOption) => {
    setSelectedCountry(selectedOption);
    setValue("pays", selectedOption ? selectedOption.label : "chargement...");
  }}
  options={countries}
  isSearchable
  placeholder="Sélectionnez un pays"
  styles={customStyles}
/>
            </div>
            <div className="mb-4">
              <label
                htmlFor="ville"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Ville
              </label>
              <input
                {...register("ville")}
                defaultValue={IncubateurData?.ville}
                type="ville"
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
                // placeholder="Ma ville"
              />
            </div>
          <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Numéro de téléphone
                  </label>
                  <div className="flex sm:flex-col items-center gap-3">
                    <div className="w-2/12 sm:w-full">
                      <select
                        value={selectedCountry?.value || "chargement"}
                        onChange={(e) => {
                          const selectedOption = countries.find(
                            (country) => country.value === e.target.value
                          );
                          setSelectedCountry(selectedOption || null);
                        }}
                        className="w-full px-1 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
                      >
                        {countries.map((country) => (
                          <option key={country.value} value={country.value}>
                            {`${country.callingCode}`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-10/12 sm:w-full">
                      {/* Champ pour le numéro de téléphone sans l'indicatif */}
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="number"
                            className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[#E87930]"
                      defaultValue={IncubateurData?.phone}
                      value={field.value}
                            onChange={(e) => {
                              const phoneNumber = e.target.value.replace(+`${selectedCountry?.callingCode}`, "").trim();
                              field.onChange(phoneNumber);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <SubmitButton buttonText={"Enregistrer"} isLoading={updateLoading} />
      </form>
    </div>
      </Profils>
    </>
  );
};

export default Profil;
