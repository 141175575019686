import React from "react";
import Tabs from "./Tabs";
import FormMPW from "./FormMPW";
import ProfileUpdate from "./ProfileUpdate";

const Profils = ({
  buttonStyle,
  children,
  backgroundIcon,
  backgroundLogo
}) => {
  const tabs = [
    {
      id: "profile",
      label: "Modifier le Profil",
      content: (
        <div>
          {children}
        </div>
      ),
    },
    {
      id: "password",
      label: "Modifier le Mot de passe",
      content: (
        <div>
          <FormMPW
            labelsCss={"block text-sm font-medium leading-6 text-gray-900"}
            buttonStyle={{ background: buttonStyle ? buttonStyle : "green" }}
            backgroundColor={backgroundLogo}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="p-4 bg-white rounded-md shadow-xl">
      <div className="flex flex-col items-center">
        <div className="flex flex-col items-center mt-8">
          <ProfileUpdate backgroundIcon={backgroundIcon}/>
        </div>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default Profils;
