import React from "react";
import Avatar from "assets/Images/avatar.webp";
import { NavLink } from "react-router-dom";
import Loader from "./Loader/Loader";
import { formatDate } from "utils/date"

function ListeNotification({ interesser, data, Loading }) {
  console.log({ data });
  const sortedData = [...data].sort((a, b) => new Date(b.date_subscription) - new Date(a.date_subscription));
  return (
    <ul className="pb-3">
      <li className="text-lg font-medium text-gray-900">{interesser}</li>
      {Loading && Loading ? (
        <Loader />
      ) : sortedData && sortedData.length > 0 ? (
        sortedData.map((item) => (
          <li className="my-2 bg-blue-gray-900 p-2 rounded-xl" key={item.id}>
            <NavLink to={`/projetsFinances/${item.projet_id}`} className="flex items-center gap-2">
              <span className="flex-initial w-12">
                <img
                  src={Avatar}
                  alt="Une image"
                  className="w-20 rounded-full"
                />
              </span>
              <div className="flex-initial w-[33rem] font-light sm:text-[0.55rem] text-sm text-white">
                <div className="flex justify-between items-center pb-0">
                  <span>{item?.prenom}</span>
                  <span className="text-xs sm:text-[0.45rem] text-end">
                    {formatDate(item.date_subscription)}
                  </span>
                </div>
                <p>
                  S'intéresse sur le projet de{" "}
                  <span className="font-semibold">{item?.projet_title}</span>
                </p>
              </div>
            </NavLink>
          </li>
        ))
      ) : (
        <div className="text-white text-sm my-2 bg-blue-gray-900 p-2 rounded-xl">0 intéressé pour le moment</div>
      )}
    </ul>
  );
}

export default ListeNotification;
