import { useState } from "react";

function useNumberFormatter() {
  const [formattedValues, setFormattedValues] = useState({});
  const formatNumber = (value) => {
    const numericValue = value.replace(/\D/g, "");
    if (!/^\d+$/.test(numericValue)) {
      return value;
    }
    const formatted = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return formatted;
  };
  const getNumericValue = (formattedValue) => {
    return formattedValue.replace(/\D/g, "");
  };
  const handleChange = (name, value) => {
    const numericValue = value.replace(/\D/g, "");
    const formattedValue = formatNumber(numericValue);
    setFormattedValues((prev) => ({
      ...prev,
      [name]: formattedValue,
    }));
  };

  return { formattedValues, getNumericValue, handleChange };
}

export default useNumberFormatter;