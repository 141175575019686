export const pays = [
    { id: 1, name: "Sénégal", value: "senegal" },
    { id: 2, name: "Nigéria", value: "nigeria" },
    { id: 3, name: "Ghana", value: "ghana" },
    { id: 4, name: "Kenya", value: "kenya" },
    { id: 5, name: "Éthiopie", value: "ethiopie" },
    { id: 6, name: "Egypte", value: "egypte" },
    { id: 7, name: "Maroc", value: "maroc" },
    { id: 8, name: "Tanzanie", value: "tanzanie" },
    { id: 9, name: "Rwanda", value: "rwanda" },
    { id: 9, name: "Égypte", value: "egypte" },
    { id: 9, name: "Maroc", value: "maroc" },
    { id: 9, name: "Afrique du sud", value: "afrique_du_Sud" }
  ];

export const stadeDevelop = [
    { id: 1, name: "Early_stage", value: "early_stage" },
    { id: 2, name: "Pre-seed", value: "pre-seed" },
    { id: 3, name: "Amorçage", value: "amorçage" },
  ];
  export const stade= [
    { id: 1, name: "MVP", value: "MVP" },
    { id: 2, name: "Finale", value: "finale" },
  ];
  export const statut= [
    { id: 1, name: "Physique", value: "physique" },
    { id: 2, name: "Morale", value: "morale" },
  ];