import React, { useState } from "react";
import { jsPDF as JsPDF } from "jspdf";
import "jspdf-autotable";
import Profile from "../assets/Images/Rectangle 12289.png";
import { AiOutlineUser } from "react-icons/ai";
import { IoBagRemoveOutline } from "react-icons/io5";
import { CiGlobe, CiMap, CiPhone } from "react-icons/ci";
import { PiEnvelope, PiPulse } from "react-icons/pi";
import Titre from "./Titre";
import SubmitButton from "./SubmitButton";
import { FaDownload } from "react-icons/fa6";

export default function ModalDetails({
    prenom,
    nom,
    profession,
    pays,
    secteurA,
    email,
    langue,
    phone
}) {
    const [open, setOpen] = useState(true);

    const infos = [
        {
            icon: <AiOutlineUser color="gray" size={30} />,
            text: "Prénom et Nom :",
            data: prenom || "Non défini"
        },
        {
            icon: <IoBagRemoveOutline color="gray" size={30} />,
            text: "Profession :",
            data: profession || "Non défini"
        },
        {
            icon: <CiMap color="gray" size={30} />,
            text: "Nationalité :",
            data: pays || "Non défini"
        },
        {
            icon: <PiPulse color="gray" size={30} />,
            text: "Secteur d'activité :",
            data: secteurA || "Non défini"
        },
        {
            icon: <CiGlobe color="gray" size={30} />,
            text: "Langue :",
            data: langue || "Non défini"
        },
        {
            icon: <PiEnvelope color="gray" size={30} />,
            text: "Email :",
            data: email || "Non défini"
        },
        {
            icon: <CiPhone color="gray" size={30} />,
            text: "Téléphone :",
            data: phone || "Non défini"
        },
    ];

    const generatePDF = () => {
        const doc = new JsPDF();

        doc.setFontSize(20);
        doc.text("Informations de l'utilisateur", 20, 20);

        const tableData = infos.map((info) => [info.text, info.data]);

        doc.autoTable({
            startY: 30,
            head: [["Information", "Détail"]],
            body: tableData,
        });

        doc.save("CV.pdf");
    };

    return (
        <div className={`fixed inset-0 z-50 overflow-y-auto ${open ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"}`}>
            <div className="flex items-center justify-center min-h-screen">
                <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={() => setOpen(false)}></div>
                <div className="bg-white rounded-lg text-left overflow-hidden shadow-xl relative sm:max-w-md w-full scale-[0.6] sm:mt-10 sm:ml-10">
                    <div className="absolute top-0 right-0 m-4">
                        <button className="text-2xl" onClick={() => setOpen(false)}>X</button>
                    </div>
                    <div className="flex sm:flex-col">
                        <div className="flex justify-center sm:justify-start">
                            <img src={Profile} alt="Profile" className="max-w-full h-auto sm:max-w-32 sm:m-auto sm:mt-4" />
                        </div>
                        <div className="shadow-lg m-3 flex-1 text-2xl">
                            <Titre titre={"A propos de moi"} size={20} />
                            {infos.slice(0, -2).map((info, k) => (
                                <div className="flex items-center sm:flex-col gap-2 p-2 ml-2" key={k}>
                                    <span>{info.icon}</span>
                                    <span>{info.text}</span>
                                    <span className="text-gray-600">{info.data}</span>
                                </div>
                            ))}
                            <Titre titre={"Description"} size={20} />
                            <div className="border border-black m-3 p-3 rounded-lg text-[20px]">
                                <p>{prenom} {nom} est un soutien précieux pour les nouvelles entreprises.
                                    Il aide les entrepreneurs à transformer leurs idées en succès
                                    en leur offrant conseils et ressources.</p>
                            </div>
                            <Titre titre={"Contact"} size={20} />
                            {infos.slice(-2).map((info, k) => (
                                <div className="flex text-1xl text-nowrap sm:flex-col items-center gap-2 p-2 ml-2   " key={k}>
                                    <span>{info.icon}</span>
                                    <span>{info.text}</span>
                                    <span className="text-gray-600">{info.data}</span>
                                </div>
                            ))}
                            <div className="text-center mb-2 mt-5 text-nowrap sm:p-5">
                                <SubmitButton
                                    onClick={generatePDF}
                                    style={{ backgroundColor: "#E87930" }}
                                    buttonText={"Télécharger mon CV"}
                                    icon={<FaDownload />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
