import React, { useState } from "react";
import { useForm } from "react-hook-form";
import SubmitButton from "./SubmitButton";
import { toast } from "react-toastify";
import { getUser } from "../redux/features/userSliceWithTokenValidation";
import { useUpdatePasswordMutation } from "../actions/auth/mutations";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useSelector } from "react-redux";

const FormMPW = ({ focusBorder, labelsCss, backgroundColor }) => {
  const { register, handleSubmit, setError, formState: { errors }, reset } = useForm();
  const currentUser = useSelector(getUser);
  const id = currentUser?.data?.id;
  const [updatePassword, { isLoading: updateLoading }] = useUpdatePasswordMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showCfrmPassword, setShowCfrmPassword] = useState(false);

  const onSubmit = async (data) => {
    if (data.new_password !== data.confirmPassword) {
      setError("confirmPassword", { type: "manual", message: "Les mots de passe ne correspondent pas." });
      return;
    }

    try {
      if (!id) {
        throw new Error("User ID is missing");
      }
      await updatePassword({ id, ...data }).unwrap();
      toast.success("Mot de passe mis à jour avec succès !");
      reset();
    } catch (err) {
      if (err.status === 400 && err.data.old_password && err.data.old_password.includes("Wrong password.")) {
        setError("old_password", { type: "manual", message: "Le mot de passe actuel est incorrect." });
      } else {
        console.error("Échec de la mise à jour du mot de passe:", err);
        toast.error("Échec de la mise à jour du mot de passe.");
      }
    }
  };

  return (
    <div className="rounded pb-8 mb-4">
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col sm:flex-col sm:w-full">
        <div className="flex sm:flex-col gap-16 sm:gap-2">
          <div className="w-1/2 sm:w-full">
            <div className="">
              <label htmlFor="oldPwd" className={labelsCss}>
                Mot de passe actuel
              </label>
              <div className="flex items-center w-full bg-[var(--primaryColor)] text-[var(--nonaryTextColor)] relative mx-0">
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("old_password", { required: "Ce champ est obligatoire" })}
                  id="oldPwd"
                  className={`w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none ${focusBorder}`}
                />
                <span
                  className="ms-[-26px]"
                  onClick={() => setShowPassword(!showPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              {errors.old_password && <p className="text-red-500">{errors.old_password.message}</p>}
            </div>
            <div className="">
              <label htmlFor="newPwd" className={labelsCss}>
                Nouveau mot de passe
              </label>
              <div className="flex items-center w-full bg-[var(--primaryColor)] text-[var(--nonaryTextColor)] relative mx-0">
                <input
                  type={showNewPassword ? "text" : "password"}
                  {...register("new_password", { required: "Ce champ est obligatoire" })}
                  id="newPwd"
                  className={`w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none ${focusBorder}`}
                />
                <span
                  className="ms-[-26px]"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              {errors.new_password && <p className="text-red-500">{errors.new_password.message}</p>}
            </div>
            <div className="">
              <label htmlFor="cfrmPwd" className={labelsCss}>
                Confirmer le mot de passe
              </label>
              <div className="flex items-center w-full bg-[var(--primaryColor)] text-[var(--nonaryTextColor)] relative mx-0">
                <input
                  type={showCfrmPassword ? "text" : "password"}
                  {...register("confirmPassword", { required: "Ce champ est obligatoire" })}
                  id="cfrmPwd"
                  className={`w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none ${focusBorder}`}
                />
                <span
                  className="ms-[-26px]"
                  onClick={() => setShowCfrmPassword(!showCfrmPassword)}
                  style={{ cursor: "pointer" }}
                >
                  {showCfrmPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
              {errors.confirmPassword && <p className="text-red-500">{errors.confirmPassword.message}</p>}
            </div>
          </div>
        </div>
        <div className="flex justify-start">
          <SubmitButton buttonText="Modifier le mot de passe" isLoading={updateLoading} />
        </div>
      </form>
    </div>
  );
};

export default FormMPW;
