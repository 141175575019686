import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #000;
  font-family:poppins;
  font-weight:400;
`;

const StyledTable = styled.table`
  width: 90%;
  margin: 0;
  background:#fff;
   td {
    border-bottom: 2px solid #EDEDED; 
    border-right: 2px solid #EDEDED;  
    text-align: start;
    padding: 8px;
  }
  
thead {
    font-weight: bold; /* Texte en gras */
    font-size:16px;
  }
  th {
    background-color: ${({ bgColor }) => bgColor || "#F3F6F9"};
    padding-top:1rem;
    text-align: start;
    padding: 10px;
    font-size: ${({ size }) => size || ""};
  }

  tr:hover {
    background-color: #f1f1f1;
  }
`;

// Table Component
const TableComponent = ({ data = [], columns = [], bgColor, size, color }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  // Fonction pour afficher les données de la page courante
  const currentData = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Fonction pour changer de page
  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <Container>
      <div className="overflow-x-auto w-full">
      <StyledTable bgColor={bgColor} size={size} color={color} className="rounded-lg text-nowrap sm:text-left">
        {/* Table header */}
        <thead>
          <tr>
            {Array.isArray(columns) &&
              columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}
          </tr>
        </thead>
        {/* Table body with current page data */}
        <tbody className="">
          {Array.isArray(currentData) &&
            currentData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{row[column.accessor]}</td>
                ))}
              </tr>
            ))}
        </tbody>
      </StyledTable>
      </div>
      {/* Pagination numbers */}
      <div className="flex ms-auto mt-4 gap-3">
        {Array.from({ length: totalPages }).map((_, index) => (
          <div
            key={index}
            className={`border bg-white shadow-lg w-8 h-8 rounded-full flex items-center justify-center cursor-pointer ${currentPage === index + 1 ? "bg-orange-300 text-black" : ""
              }`}
            onClick={() => changePage(index + 1)}
          >
            {index + 1}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default TableComponent;
