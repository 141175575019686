import React from "react";
import Titre from "components/Titre";
import TableComponent from "components/TableComponents";
import { FaRegFilePdf } from "react-icons/fa";
import { HiOutlineDownload } from "react-icons/hi";
import ModalAjout from "layouts/modal/ModalAjout";
import AjoutRapport from "./AjoutRapport";
import Loader from "components/Loader/Loader";
import { useGetRapportIdQuery } from "actions/rapport/queries";
import useCurrentUser from "hooks/useCurrentUser";
import styled from "styled-components";
import { formDate } from "utils/date";
import axios from "axios";
import { saveAs } from "file-saver";
import { API_STORAGE } from "routes/api/endpoints";
import formatNumber from "utils/FormatNumber";

const RapportTable = styled.div`
   /* Assurez-vous que le tableau utilise toute la largeur disponible */
 table {
    width:60%;
    border:1px solid #EDEDED;
  }

  thead {
    color: #369e8a;
    font-weight: bold; /* Texte en gras */
    height:40px;
  }

  th {
    padding: 10px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap; /* Empêche le texte de se couper sur plusieurs lignes */
  }

  td {
    padding: 13px;
    font-weight: 400;
    border-right:2px solid #EDEDED;
    font-size: 10px; /* Taille de texte réduite */
    white-space: nowrap; /* Empêche le texte de se couper sur plusieurs lignes */
    overflow: hidden; /* Masque le texte qui dépasse */
    text-overflow: ellipsis; /* Ajoute des points de suspension si le texte dépasse */
  }
  .column-4 {
    color: #369e8a; /* Couleur du texte pour la colonne 4 */
  }
`;

function RapportEntrepreneur() {
  const current_user = useCurrentUser();
  const userId = current_user?.id;
  console.log({ userId });
  const { data: getRapport, isLoading } = useGetRapportIdQuery(userId);
  console.log({ getRapport });
  const rapports = getRapport?.results || [];

  const downloadFile = async (fileUrl) => {
    try {
      const response = await axios.get(`${API_STORAGE}${fileUrl}`, {
        responseType: "blob", // Assurez-vous de traiter la réponse comme un blob
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, "rapport.pdf"); // Vous pouvez modifier le nom du fichier ici
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier:", error);
    }
  };

  const data = rapports
    .slice()
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
    .map((item) => ({
      id: item.id,
      rapport: formDate(item.created_at) || "Non défini",
      nomProjet: item.projet.title || "Non défini",
      montant: item.montant_depense ? formatNumber(item.montant_depense.toString()) : "Non défini",
      client: item.nombres_clients ? formatNumber(item.nombres_clients.toString()) : "Non défini",
      employes: item.nombres_employes ? formatNumber(item.nombres_employes.toString()) : "Non défini",
      chiffre: item.chiffres_daffaire ? formatNumber(item.chiffres_daffaire.toString()) : "Non défini",
      state: item.statut || "Non défini",
      commentaire: item.description || "Non défini",
      fichier: (
        <div>
          <div className="flex items-center gap-1">
            <FaRegFilePdf className="text-red-500" />
            <p>Télécharger le rapport</p>
            <button onClick={() => downloadFile(item.file)}>
              <HiOutlineDownload />
            </button>
          </div>
        </div>
      ),
    }));

  const columns = [
    { id: 1, header: "Rapport du mois", accessor: "rapport" },
    { id: 2, header: "Projet", accessor: "nomProjet" },
    { id: 3, header: "Montant dépensé", accessor: "montant" },
    { id: 4, header: "Nombre de clients", accessor: "client" },
    { id: 5, header: "Nombre d'employés", accessor: "employes" },
    { id: 6, header: "Chiffre d'affaires", accessor: "chiffre" },
    { id: 7, header: "State", accessor: "state" },
    { id: 8, header: "Commentaires", accessor: "commentaire" },
    { id: 9, header: "Fichier du rapport", accessor: "fichier" },
  ];

  const [showModal, setShowModal] = React.useState(false);

  return (
    <div className="bg-white h-[89vh] rounded-lg">
      <Titre titre={"Rapport"} bold={true} />
      <div>
        <div className="flex items-center justify-end">
          <button
            className="flex items-center justify-end gap-1 px-8 py-3 rounded-lg
             bg-[var(--secondary-color)] text-white text-[12px] relative right-4 bottom-12 sm:right-0 py-1 bottom-13 text-nowrap"
            type="button"
            onClick={() => setShowModal(true)}
          >
            <span>Ajouter un rapport</span>
          </button>
        </div> <br />
        <ModalAjout
          titre="Ajouter un projet"
          onClose={() => setShowModal(false)}
          showModal={showModal}
        >
          <AjoutRapport onClick={() => setShowModal(false)} />
        </ModalAjout>
          <RapportTable>
            {isLoading ? (
              <Loader />
            ) : data && data.length > 0 ? (
            <div className="bg-white relative left-8 w-[95%] border border [#EDEDED]">
              <TableComponent
                data={data}
                columns={columns}
                // bgColor={"var(--septanaryColor)"}
              />
            </div>
            ) : (
              <div className="">Pas de données</div>
            )}
          </RapportTable>

      </div>
    </div>
  );
}

export default RapportEntrepreneur;
