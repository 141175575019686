import { ApiMananger } from "actions/rtkquery";
import { POST_RAPPORT_BY_ENTREPRENEUR } from "routes/api/endpoints"

const projetsApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        postRapportByEntrepreneur: build.mutation({
            query({ id, body }) {
                return {
                    url: `${POST_RAPPORT_BY_ENTREPRENEUR}${id}/`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_Projets", "rapport"],
        }),
    }),
});

export const {
    usePostRapportByEntrepreneurMutation
} = projetsApi;