import moment from "moment";
import "moment/locale/fr"; // Importer le module de localisation française

// Configurer moment pour utiliser la localisation française
moment.locale("fr");

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const diff = now - date;

  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds < 60) {
    return "Il y a une seconde";
  } else if (minutes < 60) {
    return `Il y a ${minutes} minute${minutes > 1 ? "s" : ""}`;
  } else if (hours < 24) {
    return `Il y a ${hours} heure${hours > 1 ? "s" : ""}`;
  } else if (days === 1) {
    return "Hier";
  } else if (days <= 2) {
    return `${days} jours`;
  } else {
    return `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  }
};
const formeDate = (date) => {
  return date ? moment(date).format("dddd DD MMMM YYYY") : "";
};

const formDate = (date) => {
  return date ? moment(date).format("DD/M/YYYY") : "";
};

const mois = (date) => {
  return date ? moment(date).format("DD MMMM") : "";
};
export { formatDate, formeDate, formDate, mois };
