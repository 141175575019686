import React from "react";
import { NavLink } from "react-router-dom";
import { LOGIN_PATH } from "routes/navigation/navigationPaths";

function Accueil() {
  return (
    <div>
      <div className="auth-submit-btn-content flex justify-center">
        <NavLink
          to={LOGIN_PATH}
          type="submit"
          data-testid="btnId"
          className="btn text-white m-4 rounded auth-submit-btn"
        >
          Se connecter
        </NavLink>
      </div>
    </div>
  );
}

export default Accueil;
