import React from "react";
import Titre from "../../../components/Titre";
import TableComponent from "../../../components/TableComponents";
import { FaRegFilePdf } from "react-icons/fa";
import { HiOutlineDownload } from "react-icons/hi";
import { useGetListRapportIdQuery } from "actions/rapport/queries";
import useCurrentUser from "hooks/useCurrentUser";
import Loader from "components/Loader/Loader";
import { formDate } from "utils/date";
import styled from "styled-components";

// Styles pour le tableau
const StyledTable = styled.div `
table{
    width:96%;
    border:2px solid #EDEDED;

    }
thead{
color:#E87930;
}
td{
 padding:13px;
 border-right:2px solid #EDEDED;
 padding:10px;
}
`
function Rapport() {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  const { data: rapportData, isLoading } = useGetListRapportIdQuery(id);
  console.log({ rapportData });
  const rapport = rapportData?.results || [];
  console.log({ rapport });
  const downloadFile = async (fileUrl) => {
    try {
      const response = await axios.get(`${API_STORAGE}${fileUrl}`, {
        responseType: "blob", // Assurez-vous de traiter la réponse comme un blob
      });
      const blob = new Blob([response.data], { type: "application/pdf" });
      saveAs(blob, "rapport.pdf"); // Vous pouvez modifier le nom du fichier ici
    } catch (error) {
      console.error("Erreur lors du téléchargement du fichier:", error);
    }
  };
  const data = rapport?.map((item) => ({
    date: formDate(item.created_at) || "Non défini",
    nomProjet: item.title || "Non défini",
    nomPorteur: `${item.prenom} ${item.nom}` || "Non défini",
    revenu: item.chiffres_daffaire || "Non défini",
    rapport: (
      <div>
        <div className="flex items-center gap-1">
          <FaRegFilePdf className="text-red-500" />
          <p>Télécharger le rapport</p>
          <button onClick={() => downloadFile(item.file)}>
            <HiOutlineDownload />
          </button>
        </div>
      </div>
    ),
  }));
  const columns = [
    { id: 1, header: "Date", accessor: "date" },
    { id: 2, header: "Nom du projet", accessor: "nomProjet" },
    { id: 3, header: "Nom du porteur de projet", accessor: "nomPorteur" },
    { id: 4, header: "Revenues générées", accessor: "revenu" },
    { id: 5, header: "Rapports", accessor: "rapport" },
  ];

  return (
    <div className="bg-white h-max rounded-md">
      <Titre titre={"Rapport"} bold={true} />
      {isLoading ? (
        <Loader />
      ) : data && data.length > 0 ? (
        <StyledTable>
        <div className="bg-white rounded-lg relative left-7">
        <TableComponent
          data={data}
          columns={columns}
          bgColor={"var(--septanaryColor)"}
        />
        </div>
         </StyledTable>
      ) : (
        <div className="">Pas de données</div>
        )}
    </div>
  );
}

export default Rapport;
