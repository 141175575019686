// !GERER L'AUTORISATION D'ACCESS A VOTRE APPLICATION

import { useSelector } from "react-redux";
import { getUserStatus } from "../redux/features/userSliceWithTokenValidation";

/**
 *
 * @return {true|false}
 */
function useGetAuthenticate() {
    // const isAuthenticated = true
     const isAuthenticated = useSelector(getUserStatus);


    return isAuthenticated;
}

export default useGetAuthenticate;
