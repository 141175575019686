import { ApiMananger } from "actions/rtkquery";
import { POST_SUBSCRIBER, DELETE_SUBSCRIBER } from "routes/api/endpoints"

const userApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    postSouscription: build.mutation({
      query(body) {
        return {
          url: POST_SUBSCRIBER,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_Projets"],
    }),
    deleteSouscription: build.mutation({
      query({ user_id, projet_id }) {
        return {
          url: `${DELETE_SUBSCRIBER}${user_id}/${projet_id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["INVALIDATE_Projets"],
    }),
  }),
});

export const {
  usePostSouscriptionMutation,
  useDeleteSouscriptionMutation
} = userApi;
