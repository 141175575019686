import {
  INCUBATEUR_PATH,
  LISTES_PORTEURS_PROJET_PATH,
  PROJETS_PATH,
  PROJETS_FINANCES,
  LEVER_FONDS,
  PROFIL_INCUBATEUR,
  RAPPORT,
  INVESTISSEUR_PATH,
  PROJET_INVESTISSEUR,
  RAPPORT_INVESTISSEUR,
  PROJET_FINANCER_INVESTISSEUR,
  PROFIL_Invest, ENTREPRENEUR_PATH,
  PROJET_PATH_ENTREPRENEUR,
  RAPPORTEntrepreneur,
  PROFIL_Entrepreneur
} from "./navigationPaths";
import { HiFolderOpen, HiOutlineUsers, HiOutlineUser } from "react-icons/hi2";
import { BiSolidBarChartSquare } from "react-icons/bi";
import { GiTakeMyMoney } from "react-icons/gi";
import { FaHandHoldingUsd } from "react-icons/fa";
import { CgFileDocument } from "react-icons/cg";
import { FaArrowTrendUp } from "react-icons/fa6";

/*
|--------------------------------------------------------------------------
! Sidebar Items
|--------------------------------------------------------------------------
|
| Ici se trouve tous les liens et icons qui doivent etre sur le sidebar
| de SplitShell
*/
export const links = [
  { path: INCUBATEUR_PATH, icon: BiSolidBarChartSquare, label: "Dashboard" },
  { path: PROJETS_PATH, icon: HiFolderOpen, label: "Nos projets" },
  {
    path: LISTES_PORTEURS_PROJET_PATH,
    icon: HiOutlineUsers,
    label: "Porteurs de projets",
  },
  { path: PROJETS_FINANCES, icon: GiTakeMyMoney, label: "Projets financés" },
  { path: LEVER_FONDS, icon: FaHandHoldingUsd, label: "Levée de fonds" },
  { path: RAPPORT, icon: CgFileDocument, label: "Rapport" },
  { path: PROFIL_INCUBATEUR, icon: HiOutlineUser, label: "Mon profil" },
];

export const linksInvestisseur = [
  { path: INVESTISSEUR_PATH, icon: BiSolidBarChartSquare, label: "Dashboard" },
  { path: PROJET_INVESTISSEUR, icon: HiFolderOpen, label: "Projets" },
  {
    path: PROJET_FINANCER_INVESTISSEUR,
    icon: GiTakeMyMoney,
    label: "Projets financés",
  },
  { path: RAPPORT_INVESTISSEUR, icon: CgFileDocument, label: "Rapport" },
  { path: PROFIL_Invest, icon: HiOutlineUser, label: "Mon profil" },
];

export const linksEntrepreneur = [
  { path: ENTREPRENEUR_PATH, icon: BiSolidBarChartSquare, label: "Dashboard" },
  { path: PROJET_PATH_ENTREPRENEUR, icon: HiFolderOpen, label: "Projets" },
  { path: RAPPORTEntrepreneur, icon: FaArrowTrendUp, label: "Rapport" },
  { path: PROFIL_Entrepreneur, icon: HiOutlineUser, label: "Mon profil" },
];
