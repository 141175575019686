import React from "react";
import Titre from "../../../components/Titre";
import TableComponent from "../../../components/TableComponents";
import ProgressBar from "components/progressBar";
/* import Loader2 from "components/Loader/Loader2";
 */
import styled from "styled-components";

// Styles pour le tableau
const StyledTable = styled.div `

thead{
color:#E87930;
}
td{
 padding:13px;
 border-right:2px solid #EDEDED;
}
`
function LeverFond() {
    const data = [
        {
            id: 1,
            date: "15/09/2022",
            nomProjet: "Volkeno",
            nomPorteur: "Volkeno",
            nomInvestisseur: "Amadou Gaye",
            montant: "25.000.000FCFA",
            progression: <ProgressBar value={70}/>
        },
        {
            id: 2,
            date: "10/11/2023",
            nomProjet: "Bakéli",
            nomPorteur: "Pitch Palabre",
            nomInvestisseur: "Fatim Thiam",
            montant: "5.000.000FCFA",
            progression: <ProgressBar value={32}/>
        },
        {
            id: 3,
            date: "15/09/2022",
            nomProjet: "Volkeno",
            nomPorteur: "Volkeno",
            nomInvestisseur: "Amadou Gaye",
            montant: "25.000.000FCFA",
            progression: <ProgressBar value={87}/>
        },
      ];
    const columns = [
        { id: 1, header: "Date de la levée de fonds", accessor: "date" },
        { id: 2, header: "Nom du projet", accessor: "nomProjet" },
        { id: 3, header: "Nom du porteur de projet", accessor: "nomPorteur" },
        { id: 4, header: "Nom des investisseurs", accessor: "nomInvestisseur" },
        { id: 5, header: "Montant levée", accessor: "montant" },
        { id: 6, header: "Progression", accessor: "progression" },
      ];

  return <div className="bg-white h-[89vh] rounded-md">
    <Titre titre={"Liste des Porteurs de projets"} bold={true} />
    {/* {loadingData ? (
        <Loader2 />
      ) : data && data.length > 0 ? (
        <TableComponent data={data} columns={columns} />
      ) : (
        <div className="">Pas de données</div>
      )} */}
      <StyledTable>
      <div className="bg-white relative left-7 w-[95%] h-[330] border border [#EDEDED]">
       <TableComponent columns={columns} data={data}/>
       </div>
       </StyledTable>
  </div>;
}

export default LeverFond;
