import React from "react";
import Projet from "components/Projet";
import Titre from "components/Titre";
import { useGetListProjetFinanceByIncubateurQuery } from "../../../actions/projets/queries";
import { getUser } from "../../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { API_STORAGE } from "../../../routes/api/endpoints";
import Loader2 from "components/Loader/Loader2";

function ProjetsFinances() {
  const userStatus = useSelector(getUser);
  const current_user = userStatus.data.id;
  const { data: projectSouscrit, isLoading } = useGetListProjetFinanceByIncubateurQuery(current_user);
  console.log({ projectSouscrit });
  const projects = projectSouscrit?.results || [];

  return (
    <div>
      <Titre titre={"Projets financés"} />
      <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 p-2">
        {isLoading ? (
          <Loader2 />
        ) : projects.length > 0 ? (
          projects.map((item) => (
            <Projet
              key={item.id}
              Link={`/projetsFinances/${item.projet.id}`}
              Video={API_STORAGE + item.projet.video}
              Secteur={item.projet.secteur}
              Titre={item.projet.title}
              Financement={item.projet.montant}
              Montant={item.projet.montant_part}
              className={"hidden"}
            />
          ))
        ) : (
          <div>Aucun projet disponible.</div>
        )}
        <Outlet />
      </div>
    </div>
  );
}

export default ProjetsFinances;
