import { ApiMananger } from "actions/rtkquery";
import { POST_PROJET, POST_PROJET_BY_ENTREPRENEUR, POST_DOCUMENT } from "../../routes/api/endpoints"

const projetsApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        postProject: build.mutation({
            query(body) {
                return {
                    url: POST_PROJET,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_Projets"],
        }),
        postProjectId: build.mutation({
            query({ id, body }) {
                return {
                    url: `${POST_PROJET}${id}/`,
                    method: "PUT",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_Projets"],
        }),
        postProjectByEntrepreneur: build.mutation({
            query({ id, body }) {
                return {
                    url: `${POST_PROJET_BY_ENTREPRENEUR}${id}/`,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_Projets"],
        }),
        postDocument: build.mutation({
            query(body) {
                return {
                    url: POST_DOCUMENT,
                    method: "POST",
                    body,
                };
            },
            invalidatesTags: ["INVALIDATE_Projets"],
        }),
    }),
});

export const {
    usePostProjectMutation, usePostDocumentMutation,
    usePostProjectIdMutation, usePostProjectByEntrepreneurMutation
} = projetsApi;