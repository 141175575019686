import React, { useState } from "react";
import Titre from "components/Titre";
import PieChart from "components/Chart";
import TableComponent from "components/TableComponents";
import Loader2 from "components/Loader/Loader2";
import { useGetProjetSouscritByIncubateurQuery } from "actions/projetIncubateur/queries";
import {
  useGetProjetAndSecteurByIncubateurIdQuery,
  useGetProjetAndSecteurByIncubateurStatiqueIdQuery,
} from "actions/projetIncubateur/queries";
import useCurrentUser from "hooks/useCurrentUser";
import { formDate } from "utils/date";
import styled from "styled-components";


const StyledHeader =styled.div `
th {
 color:#E87930;
  }
td{
 padding:13px
 }  
`
function Dashboard() {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  const { data: projectSouscrit, isLoading } = useGetProjetSouscritByIncubateurQuery(id);
  const { data: projectAndSecteurByIncubateur } =
    useGetProjetAndSecteurByIncubateurIdQuery(id);
  const projectAndSecteur = projectAndSecteurByIncubateur?.results || [];
  const { data: projectAndSecteurByIncubateurStatique } =
    useGetProjetAndSecteurByIncubateurStatiqueIdQuery(id);
  const projectAndSecteurStatique =
    projectAndSecteurByIncubateurStatique?.secteurs || [];
  const filteredProject = projectSouscrit?.results;
  console.log({ projectAndSecteurByIncubateurStatique });
  const [search, setSearch] = useState("");
  const [selectedSecteur, setSelectedSecteur] = useState("");
  const [selectedProjet, setSelectedProjet] = useState("");
  console.log({ projectAndSecteurByIncubateurStatique });
  // console.log({ id });
  const columns = [
    { id: 1, header: "Nom du Projet", accessor: "NomProjet" },
    { id: 2, header: "Nom de l'investisseur", accessor: "NomPorteurProjet" },
    { id: 3, header: "Secteurs d'Activité", accessor: "SecteursAct" },
    { id: 4, header: "Montant Élevé", accessor: "Montants" },
    { id: 5, header: "Personne Intéressée", accessor: "interesser" },
    { id: 6, header: "Date du projet", accessor: "date" },
  ];
  const data = filteredProject?.map((projetSouscrit) => ({
    NomProjet: projetSouscrit?.projet?.title || "Non défini",
    NomPorteurProjet: `${projetSouscrit?.user?.prenom} ${projetSouscrit?.user?.nom || "Non défini"
      } `,
    SecteursAct: projetSouscrit?.projet?.secteur?.[0]?.name || "Non défini",
    Montants: `${projetSouscrit?.projet?.montant || "0"
      } FCFA`,
    interesser: projetSouscrit?.projet?.nb_subscriber || "Non défini",
    date: formDate(projetSouscrit?.projet?.created_at) || "Non défini",
  }));
  console.log({ filteredProject });
  const filteredData = data?.filter((row) => {
    const nomExists = row?.NomProjet && typeof row?.NomProjet === "string";
    const nomPorteurExists =
      row?.NomPorteurProjet && typeof row?.NomPorteurProjet === "string";
    const secteurMatches =
      selectedSecteur === "" || row?.SecteursAct === selectedSecteur;
    const projetMatches =
      selectedProjet === "" || row?.NomProjet === selectedProjet;
    if (nomExists && nomPorteurExists && secteurMatches && projetMatches) {
      return (
        row?.NomProjet.toLowerCase().includes(search.toLowerCase()) ||
        row?.NomPorteurProjet.toLowerCase().includes(search.toLowerCase())
      );
    }
    return false;
  });
  const dataValues = projectAndSecteur?.map((projet) => projet?.id);
  console.log("Data", dataValues);
  const labels = projectAndSecteur?.map((projet) => projet?.title);
  const secteur = projectAndSecteurStatique?.map((projet) => projet?.secteur);
  const pourcentage = projectAndSecteurStatique?.map(
    (projet) => projet?.pourcentage
  );
  const dataValuesSecteur = projectAndSecteurStatique?.map((projet) => {
    const value = parseFloat(projet?.pourcentage);
    return isNaN(value) ? 0 : value;
  });
  console.log("dataValuesSecteur:", dataValuesSecteur);
  const secteursUniques = filteredProject?.reduce((acc, projetSouscrit) => {
    const secteurName = projetSouscrit?.projet?.secteur?.[0]?.name;
    if (secteurName && !acc.includes(secteurName)) {
      acc.push(secteurName);
    }
    return acc;
  }, []);
  return (
    <>
      <Titre titre={"Dashboard"} bold={true} />
      <div className="grid grid-cols-2 md:grid-cols-1 w-100 sm:grid-cols-1 lg:grid-cols-1">
        <div>
          <PieChart
            titleChart={"Nombre de Projet"}
            labels={labels}
            numberColor={"#369E8A"}
            total={projectAndSecteurByIncubateur?.count || "0"}
            dataValues={dataValues}
            fontSize={"40px"}
          />
        </div>
        <div>
          <PieChart
            titleChart={"Nombre de projets déjà financés"}
            labels={secteur}
            numberColor={"#F68B1E"}
            showPercentages={true}
            total={
              projectAndSecteurByIncubateurStatique?.nombre_projets_investis ||
              "0"
            }
            dataValues={dataValuesSecteur}
            percentages={pourcentage}
            fontSize={"40px"}
          />
        </div>
        <div>
          <PieChart
            titleChart={"Nombre de projets déjà financés"}
            labels={secteur}
            numberColor={"#F68B1E"}
            showPercentages={true}
            total={`${projectAndSecteurByIncubateurStatique?.total_investi || "0"
              }FCFA`}
            dataValues={dataValuesSecteur}
            percentages={pourcentage}
            type="Pie1"
          />
        </div>
        <div className=" sm:flex-col">
          <PieChart
            titleChart={"Total Financements reçus"}
            labels={secteur}
            numberColor={"#F68B1E"}
            showPercentages={true}
            total={`${projectAndSecteurByIncubateurStatique?.total_investi || "0"
              }FCFA`}
            dataValues={dataValuesSecteur}
            percentages={pourcentage}
          />
        </div>
      </div>
      <div className="mt-10">
        < div className="flex justify-between items-center sm:flex-col">
          <Titre titre={"Projets Intéressés"} bold={true} size={"22px"} />
          <div className="flex items-center py-2 ps-3 mx-3 gap-4 border w-[40%] sm:w-full bg-white  rounded-md border-orange-400">
            <div className="flex-1 mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-orange-400"
              >
                Projets
              </label>
              <select
                name="projet"
                value={selectedProjet}
                onChange={(e) => setSelectedProjet(e.target.value)}
                className="form control w-full px-2 py-2 border border-orange-400 rounded outline-0 text-orange-400"
              >
                <option value="">---les projets---</option>
                {filteredProject?.map((projetSouscrit) => (
                  <option
                    key={projetSouscrit?.projet?.id}
                    value={projetSouscrit?.projet?.title}
                  >
                    {projetSouscrit?.projet?.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex-1 mb-4 mr-3">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-orange-400"
              >
                Secteurs
              </label>
              <select
                name="etapeA"
                value={selectedSecteur}
                onChange={(e) => setSelectedSecteur(e.target.value)}
                className="form control w-full px-2 py-2 border border-orange-400 rounded outline-0 text-orange-400"
              >
                <option value="">---les secteurs---</option>
                {secteursUniques?.map((secteur, index) => (
                  <option key={index} value={secteur}>
                    {secteur}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-4 sm:grid-cols-1 p-3">
          <input
            type="text"
            name="name"
            className="w-full px-3 py-2 sm:w-full border border-orange-400 rounded-full outline-0"
            placeholder="🔎 Rechercher un projet"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {isLoading ? (
          <Loader2 />
        ) : filteredData && filteredData.length > 0 ? (
          <StyledHeader>
          <TableComponent
            data={filteredData}
            columns={columns}
            bgColor={"#F3F6F9"}
            color={"#6A707E"}
            // size={"14px"}
          />
           </StyledHeader>
        ) : (
          <div className="text-xl text-red-500 pb-8">Pas de données</div>
        )}
      </div>
    </>
  );
}

export default Dashboard;
