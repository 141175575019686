import React from "react";
import ProjetDetail from "../../../components/ProjetDetail"
import { MdOutlineWatchLater } from "react-icons/md";
import IconInvestissement from "../../../assets/Images/investment (5) 1.png";
import ArrowScope from "../../../assets/Images/Vector.png";
import { API_STORAGE } from "../../../routes/api/endpoints";
import { useParams } from "react-router-dom";
import { useGetProjetsQuery } from "actions/projets/queries";
import { useGetSubscriberIdQuery } from "actions/souscrire/queries";
import { ToastContainer } from "react-toastify";
import {
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const DetailProjetFinance = () => {
  const { id } = useParams();
  const { data: projectsData, isLoading } = useGetProjetsQuery(id);
  const Span = [
    {
      icon: <MdOutlineWatchLater color="#F68B1E" size={20} />,
      title: "Date du début",
      subtitle: projectsData?.data_lancement || "Non défini",
      subtitleClass: "font-light",
    },
    {
      icon: <MdOutlineWatchLater color="#F68B1E" size={20} />,
      title: "Date d'échéance",
      subtitle: projectsData?.data_lancement || "Non défini",
      subtitleClass: "font-light",
    },
    {
      icon: <img src={IconInvestissement} alt="Icon Investissement" />,
      title: "Besoin en investissement",
      subtitle: `FCFA ${projectsData?.montant || "0"}`,
      subtitleClass: "font-bold text-orange-400",
    },
    {
      icon: <img src={ArrowScope} alt="Icon Investissement" />,
      title: "Montant atteint",
      subtitle: `FCFA ${projectsData?.montant_part || "0"}`,
      subtitleClass: "font-bold text-green-400",
    },
  ];
  const { id: projectId } = useParams();
  const { data: souscrire } = useGetSubscriberIdQuery(projectId);
  // console.log({ souscrire });
  const ReseauSociaux = [
    {
      id: 1,
      icon: <FaFacebookF />,
      link: "#"
    },
    {
      id: 2,
      icon: <FaLinkedinIn />,
      link: "#"
    },
    {
      id: 3,
      icon: <FaXTwitter />,
      link: "#"
    }
  ]
  const filteredSubscribers = Array.isArray(souscrire?.results) ? souscrire?.results?.filter((subscriber) => subscriber?.projet.id.toString() === projectId) : [];

  return (
    <div>
      <ToastContainer className={"mt-10"} />
      <ProjetDetail
        Link={"/projetsFinances"}
        video={API_STORAGE + projectsData?.video}
        title={projectsData?.title}
        description={projectsData?.description}
        data={Span}
        prenom={projectsData?.user?.prenom}
        nom={projectsData?.user?.nom}
        souscrireData={filteredSubscribers}
        projet={projectsData}
        flex={"hidden"}
        isLoading={isLoading}
        background={"var(--backgroundOrange)"}
        reseau={ReseauSociaux}
      />
    </div>
  );
};

export default DetailProjetFinance;
