import React, { useState } from "react";
import LogoSlogan from "../../components/Logo/LogoSlogan";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosMail } from "react-icons/io";
import { CiLock } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";
import { useForm } from "react-hook-form";
import { useLoginUserMutation } from "actions/auth/mutations";
// import useGetAuthenticate from "hooks/useIsAuthenticatedUser";
import { useDispatch } from "react-redux";
import { setCredentials } from "redux/features/userSliceWithTokenValidation";
import { ToastContainer, toast } from "react-toastify";
import { FiLoader } from "react-icons/fi";
import {
  INCUBATEUR_PATH,
  ACCEUIL_PATH,
  INVESTISSEUR_PATH,
  ENTREPRENEUR_PATH,
} from "../../routes/navigation/navigationPaths";

function LogIn() {
  const [login, { isLoading }] = useLoginUserMutation();
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    console.log({ data });
    login(data)
      .unwrap()
      .then((res) => {
        console.log("responses", res);
        toast.success("Connexion réussie !");
        dispatch(setCredentials(res));
        const userType = res.data.user_type; // Définir userType ici
        // navigate(INCUBATEUR_PATH)
        console.log("userType", userType);
        switch (userType) {
          case "admin":
            navigate("/" + ACCEUIL_PATH);
            break;
          case "incubateur":
            navigate("/" + INCUBATEUR_PATH); // Remplacez par le chemin de votre incubateur
            break;
          case "investisseur":
            navigate("/" + INVESTISSEUR_PATH); // Remplacez par le chemin de votre investisseur
            break;
          case "entrepreneur":
            navigate("/" + ENTREPRENEUR_PATH); // Remplacez par le chemin de votre entrepreneur
            break;
          default:
            console.log("Unknown user type:", userType);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("La connexion a échoué. Vérifiez vos identifiants.");
      });
  };

  return (
    <>
      <ToastContainer />
      <div className="containerAuth">
        <div className="flex flex-col justify-center items-center min-h-screen sm:relative right-10">
          <div className="container">
            <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 container-secondaire sm:w-[400px] pr-0 md:w-[500px]">
              <div className="divLogo flex flex-col items-center justify-center p-32 sm:p-18 md:p-20">
                <LogoSlogan />
              </div>
              <div className="flex flex-col items-center justify-center py-32 bg-white bordureAuth">
                <div className="w-8/12">
                  <p className="titreConnexion text-center mb-6 relative bottom-16">
                    Connectez-vous
                  </p>
                  <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="auth-form-content">
                      <div className="mb-6">
                        <div className="flex items-center w-full bg-[var(--primaryColor)] text-[var(--nonaryTextColor)] relative mx-0 border-b-2 ">
                          <span className="absolute ms-[-1px] text-[var(--septanaryBorderColor)] text-base">
                            <IoIosMail className="colorIcon" />
                          </span>
                          <input
                            type="email"
                            className="w-full px-7 py-2 text-[var(--nonaryTextColor)] text-base focus:outline-none cv-input-with-icon"
                            {...register("email", {
                              required: "L'email est requis",
                            })}
                            placeholder="Votre mail"
                          />
                        </div>
                        <div className="text-red-500 text-base">
                          {errors?.email?.message}
                        </div>
                      </div>
                      <div className="mb-6">
                        <div className="flex items-center w-full bg-[var(--primaryColor)] text-[var(--nonaryTextColor)] relative mx-0 border-b-2 ">
                          <span className="absolute ms-[-1px] text-[var(--septanaryBorderColor)] text-base">
                            <CiLock className="colorIcon" />
                          </span>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="w-full px-7 py-2 text-[var(--nonaryTextColor)] text-base focus:outline-none cv-input-with-icon"
                            {...register("password", {
                              required: "Le mot de passe est requis",
                            })}
                            placeholder="Votre mot de passe"
                          />
                          <span
                            className="password-toggle-icon"
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                        <div className="text-red-500 text-base">
                          {errors?.password?.message}
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end">
                      <NavLink
                        className="no-underline text-[13px] text-[var(--septanaryBorderColor)] mdpOublie"
                        to="/request-reset-password"
                      >
                        Mot de passe oublié ?
                      </NavLink>
                    </div>
                    <div className="auth-submit-btn-container">
                      <div className="auth-submit-btn-content flex justify-center">
                        <button
                          type="submit"
                          data-testid="btnId"
                          className="btn text-white m-4 rounded-lg text-[13px] font-bold auth-submit-btn"
                        >
                          {isLoading ? (
                            <div className="flex items-center gap-1">
                              Connexion
                              <FiLoader className="text-white text-xl animate-spin" />
                            </div>
                          ) : (
                            "Connexion"
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                  <div className="other-auth-action-row">
                    <p className="text-center auth-action-textual text-[#000000]">
                      Vous n’avez pas de compte ? Inscrivez-vous{" "}
                      <NavLink className="no-underline mdpOublie" to="">
                         ici
                      </NavLink>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LogIn;
