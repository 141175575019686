import { ApiMananger } from "actions/rtkquery";
import { GET_SUBSCRIBER, NOTIFICATION } from "routes/api/endpoints"

const souscrireApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getSubscriberId: build.query({
            query: () => GET_SUBSCRIBER,
            providesTags: ["INVALIDATE_Projets"],
        }),
        notification: build.query({
            query: (id) => `${NOTIFICATION}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
    }),
});

export const {
    useGetSubscriberIdQuery,
    useNotificationQuery,
} = souscrireApi;