const { createSlice } = require("@reduxjs/toolkit");

const useSliceWithTokenValidation = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    refreshToken: null,
    isAuthenticated: false,
    currentUser: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      state.refreshToken = action.payload.refresh;
      state.accessToken = action.payload.token;
      state.isAuthenticated = true; // Mettre à jour isAuthenticated à true lors de la connexion réussie
      state.currentUser = action.payload;
      // console.log("action.payload", action.payload);
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
      // console.log("setRefreshToken", state);
    },
    setAccessToken: (state, action) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload;
      // console.log("setAccessToken", state.accessToken);
    },

    setSkeep(state) {
      state.isSkeep = true;
    },

    updateUserSuccess: (state, action) => {
      state.currentUser = action.payload;
    },

    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.currentUser = null;
    },
  },
});

export const getUserStatus = (state) => state?.auth?.isAuthenticated;
export const getUser = (state) => state?.auth?.currentUser;
export const getUsers = (state) => state?.auth;

export const getSkeep = (state) => state?.user?.isSkeep;

// export const getProjetByIncubateurId = (state) => state?.auth;
// console.log("ProjetsIncuber", getProjetByIncubateurId);
// getSubscriberId

export const { setCredentials, setAccessToken, logout, setSkeep, updateUserSuccess } =
  useSliceWithTokenValidation.actions;

export const loginSuccess = (state) => {
  state.isAuthenticated = true;
};
export default useSliceWithTokenValidation.reducer;
