import { ApiMananger } from "actions/rtkquery";
import { LOGIN_ROUTE, UPDATE_ROUTE, UPDATE_PASSWORD, UPDATE_ENTREPRENEUR,
  UPDATE_INCUBATEURID
 } from "../../routes/api/endpoints";

const userApi = ApiMananger.injectEndpoints({
  endpoints: (build) => ({
    loginUser: build.mutation({
      query(body) {
        return {
          url: LOGIN_ROUTE,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    updateUser: build.mutation({
      query({ id, body }) {
        return {
          url: `${UPDATE_ROUTE}${id}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    updateEntrepreneur: build.mutation({
      query({ id, body }) {
        return {
          url: `${UPDATE_ENTREPRENEUR}${id}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    updateIncubateurId: build.mutation({
      query({ id, body }) {
        return {
          url: `${UPDATE_INCUBATEURID}${id}/`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    registerUser: build.mutation({
      query(body) {
        return {
          url: "API.USER_API.REGISTER()",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    forgetPassword: build.mutation({
      query(body) {
        return {
          url: "API.USER_API.FORGET_PASSWORD()",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    otpVerification: build.mutation({
      query(body) {
        return {
          url: "API.USER_API.OTP_VERIFICATION()",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    resetPassword: build.mutation({
      query(body) {
        return {
          url: "API.USER_API.RESET_PASSWORD()",
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
    updatePassword: build.mutation({
      query(body) {
        return {
          url: UPDATE_PASSWORD,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["INVALIDATE_USERS"],
    }),
  }),
});

export const {
  useLoginUserMutation,
  useRegisterUserMutation,
  useUpdateUserMutation,
  useUpdatePasswordMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useOtpVerificationMutation,
  useUpdateEntrepreneurMutation,
  useUpdateIncubateurIdMutation,
} = userApi;
