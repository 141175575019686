import React from "react";

function StatutProjet({
    Icon,
    NomStatut,
    display,
    background
}) {
  return (
    <span
      className="flex items-center justify-center gap-1"
      style={{
        background: `${background}`,
        padding: "15.44px",
        borderRadius: "11.42px",
        display: `${display}`,
      }}
    >
      <span>
        {Icon}
      </span>
      <span>{ NomStatut }</span>
    </span>
  );
}

export default StatutProjet;
