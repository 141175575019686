import React from "react";
import styled from "styled-components";
import Titre from "../../../components/Titre";
import TableComponent from "../../../components/TableComponents"; // Assurez-vous que le nom du fichier est correct
import { FaRegFilePdf } from "react-icons/fa";
import { HiOutlineDownload } from "react-icons/hi";

// Styles pour le conteneur
const Container = styled.div`
  background-color: #fff;
`;

// Styles pour le tableau
const RapportTable = styled.div`
  table{
    width:96%;
    border:2px solid #EDEDED;

    }
  thead {
    background-color: #F3F7FF;
    color: #369e8a;
    font-weight: bold; /* Texte en gras */
    height:40px;
  }
  th {
    padding:6px;
    text-align: center;
    border-bottom: 1px solid #F3F7FF;
    font-size:14px;
    white-space: nowrap; /* Empêche le texte de se couper sur plusieurs lignes */
  }
  td {
    padding:13px;
    border-right: 2px solid #EDEDED;
  }
  .column-4 {
    color: #369e8a; /* Couleur du texte pour la colonne 4 */
  }
`;

function Rapport() {
  const data = [
    {
      id: 1,
      date: "15/09/2022",
      nomProjet: "Volkeno",
      nomPorteur: "Babacar Sarr",
      revenu: "5.000.000FCFA",
      rapport: (
        <div className="flex items-center gap-1">
          <FaRegFilePdf className="text-red-500" /> <p>Rapport du premier mois</p> <HiOutlineDownload />
        </div>
      ),
    },
    {
      id: 2,
      date: "10/11/2023",
      nomProjet: "Elikia",
      nomPorteur: "Chris lyoka",
      revenu: "15.000.000FCFA",
      rapport: (
        <div className="flex items-center gap-1">
          <FaRegFilePdf className="text-red-500" /> <p>Rapport du premier mois</p> <HiOutlineDownload />
        </div>
      ),
    },
    // ... autres données
    {
      id: 3,
      date: "10/11/2023",
      nomProjet: "Bakéli",
      nomPorteur: "Abdou Khadre Diallo",
      revenu: "25.000.000FCFA",
      rapport: (
        <div className="flex items-center gap-1">
          <FaRegFilePdf className="text-red-500" /> <p>Rapport du premier mois</p> <HiOutlineDownload />
        </div>
      ),
    },
    {
      id: 4,
      date: "10/11/2023",
      nomProjet: "Volkeno",
      nomPorteur: "Samuel Sarr",
      revenu: "500.000FCFA",
      rapport: (
        <div className="flex items-center gap-1">
          <FaRegFilePdf className="text-red-500" /> <p>Rapport du premier mois</p> <HiOutlineDownload />
        </div>
      ),
    },
    {
      id: 5,
      date: "10/11/2023",
      nomProjet: "Elikia",
      nomPorteur: "Mansour Faye",
      revenu: "2.000.000FCFA",
      rapport: (
        <div className="flex items-center gap-1">
          <FaRegFilePdf className="text-red-500" /> <p>Rapport du premier mois</p> <HiOutlineDownload />
        </div>
      ),
    },
  ];

  const columns = [
    { id: 1, header: "Date", accessor: "date" },
    { id: 2, header: "Nom du projet", accessor: "nomProjet" },
    { id: 3, header: "Nom du porteur de projet", accessor: "nomPorteur" },
    { id: 4, header: "Revenues générées", accessor: "revenu" },
    { id: 5, header: "Rapports", accessor: "rapport" },
  ];

  return (
    <div className="bg-white h-[90vh] rounded-md">
      <Titre titre={"Rapport"} bold={true} />
      <div className="bg-white rounded-lg relative left-7">
      <Container>
        <RapportTable>
          <TableComponent
            data={data}
            columns={columns}
          />
        </RapportTable>
      </Container>
    </div>
    </div>
  );
}

export default Rapport;
