import React, { useState } from "react";
import ProjetDetail from "../../../components/ProjetDetail";
import { MdOutlineWatchLater } from "react-icons/md";
import IconInvestissement from "../../../assets/Images/investment (5) 1.png";
import ArrowScope from "../../../assets/Images/Vector.png";
import { useParams } from "react-router-dom";
import { useGetProjetsQuery } from "actions/projets/queries";
import { useGetSubscriberIdQuery } from "actions/souscrire/queries";
import { usePostSouscriptionMutation, useDeleteSouscriptionMutation } from "actions/souscrire/mutations";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getUser } from "redux/features/userSliceWithTokenValidation";
import {
    FaFacebookF,
    FaLinkedinIn,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
/* import { store } from "react-notifications-component"; */

const DetailProjet = () => {
    const { id } = useParams();
    const { data: projectsData, isLoading } = useGetProjetsQuery(id);
    const { data: souscrire } = useGetSubscriberIdQuery(id);
    const currentUser = useSelector(getUser);
    const userId = currentUser?.data?.id;
    console.log({ souscrire });
    const [postSouscription, { isLoading: isUpdating }] = usePostSouscriptionMutation();
    const [deleteSubscriber] = useDeleteSouscriptionMutation();
    const Span = [
        {
            icon: <MdOutlineWatchLater color="#F68B1E" size={20} />,
            title: "Date du début",
            subtitle: projectsData?.data_lancement || "Non défini",
            subtitleClass: "font-light",
        },
        {
            icon: <MdOutlineWatchLater color="#F68B1E" size={20} />,
            title: "Date d'échéance",
            subtitle: projectsData?.data_lancement || "Non défini",
            subtitleClass: "font-light",
        },
        {
            icon: <img src={IconInvestissement} alt="Icon Investissement" />,
            title: "Besoin en investissement",
            subtitle: `FCFA ${projectsData?.montant || "0"}`,
            subtitleClass: "font-bold text-orange-400",
        },
        {
            icon: <img src={ArrowScope} alt="Icon Investissement" />,
            title: "Montant atteint",
            subtitle: `FCFA ${projectsData?.montant_part || "0"}`,
            subtitleClass: "font-bold text-green-400",
        },
    ];

    const [isSubscriber, setIsSubscriber] = useState(false)
    const filteredSubscribers = Array.isArray(souscrire?.results) ? souscrire?.results?.filter((subscriber) => subscriber?.projet.id.toString() === id) : [];
    const ReseauSociaux = [
        {
            id: 1,
            icon: <FaFacebookF />,
            link: "#"
        },
        {
            id: 2,
            icon: <FaLinkedinIn />,
            link: "#"
        },
        {
            id: 3,
            icon: <FaXTwitter />,
            link: "#"
        }
    ]
    const handleSouscrire = async () => {
        if (isSubscriber) {
            const souscrireData = {
                user_id: userId,
                projet_id: parseInt(id)
            };
            try {
                const { error } = await deleteSubscriber(souscrireData);
                if (error) {
                    // Handle error
                    console.error("Error deleting subscription:", error);
                    toast.error("Une erreur est survenue lors de la désouscription.");
                } else {
                    toast.success("Votre désouscription a été enregistrée avec succès.");
                    // Handle success
                }
            } catch (error) {
                // Handle unexpected error
                console.error("Unexpected error deleting subscription:", error);
                toast.error("Une erreur inattendue est survenue lors de la désouscription.");
            }
            setIsSubscriber(false);
        } else {
            const souscrireData = {
                is_subscriber: true,
                user: userId,
                projet: parseInt(id),
            };
            try {
                const { error } = await postSouscription(souscrireData);
                if (error) {
                    // Handle error
                    console.error("Error creating subscription:", error);
                    toast.error("Une erreur est survenue lors de la souscription.");
                } else {
                    toast.success("Votre souscription a été enregistrée avec succès.");
                    // Handle success
                }
            } catch (error) {
                // Handle unexpected error
                console.error("Unexpected error creating subscription:", error);
                toast.error("Une erreur inattendue est survenue lors de la souscription.");
            }
            setIsSubscriber(true);
        }
    };

    return (
        <div>
            <ToastContainer className={"mt-10"} />
            <ProjetDetail
                Link={"/projetsInvest"}
                video={projectsData?.url_demo}
                title={projectsData?.title}
                description={projectsData?.description}
                data={Span}
                prenom={projectsData?.user?.prenom}
                nom={projectsData?.user?.nom}
                souscrireData={filteredSubscribers}
                projet={projectsData}
                flex={"hidden"}
                onClickInteresser={handleSouscrire}
                Interreser={projectsData?.nb_subscriber}
                disabledInteresser={isUpdating || projectsData?.nb_subscriber !== 0}
                isLoading={isLoading}
                reseau={ReseauSociaux}
            />
        </div>
    );
};

export default DetailProjet;
