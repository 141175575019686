import React from "react"
import { useForm } from "react-hook-form";
import { useUpdateUserMutation } from "actions/auth/mutations";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton";
import useCurrentUser from "hooks/useCurrentUser";
import { useDispatch } from "react-redux";
import { updateUserSuccess } from "redux/features/userSliceWithTokenValidation"

function ProfileUpdateUser({ onClick }) {
    const currentUser = useCurrentUser();
    const id = currentUser?.id;
    const [updateUser, { isLoading: updateLoading }] = useUpdateUserMutation();
  const { register, handleSubmit } = useForm();
    const dispatch = useDispatch();
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append("avatar", data.avatar[0] || "");
        try {
          const response = await updateUser({ id, body: formData });
          dispatch(updateUserSuccess(response));
          toast.success("Profil mis à jour avec succès !");
          onClick();
        } catch (err) {
          console.error("Échec de la mise à jour du profil:", err);
          toast.error("Échec de la mise à jour du profil.");
        }
      };
    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <div className="p-6">
        <div className="mb-4">
                <label
                  htmlFor="image"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                 Image
                </label>
                <input
                  {...register("avatar")}
                  type="file"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded"
                />
              </div>
              <div className="flex justify-end items-end gap-3 ">
                <button type="button" onClick={onClick} className="bg-red-500 text-white px-5 py-2 rounded">Annuler</button>
              <SubmitButton buttonText={"Modifier"} isLoading={updateLoading} />
              </div>
        </div>
      </form>
        </div>
    )
}

export default ProfileUpdateUser