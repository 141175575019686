import React, { cloneElement } from "react";
import SidebarItem from "../../components/SidebarItem";
import ResourceDataMap from "../../components/ResourceDataMap";

const SidebarIncubateurComponent = ({ bgColor, activeLinkColor, activeLinkBgColor, colorOnHover, linkColor, resourceData }) => {
  const ClonedSidebarItem = ({ linkItem }) => cloneElement(<SidebarItem linkItem={linkItem} />, {
    activeLinkColor,
    activeLinkBgColor,
    colorOnHover,
    linkColor
  })
  return (
    <div
      style={{
        backgroundColor: bgColor ? bgColor : "#fff",
        height: "100%",
        // padding: "25px 10px",
        borderRight: "1px solid rgba(0,0,0,0.1)"
      }}
    >
      <p className="font-medium text-base ms-0 ps-3 p-4" style={{ color: "var(--nonaryTextColor)" }}>Menu</p>
      <ResourceDataMap
        resourceData={resourceData}
        resourceItem={ClonedSidebarItem}
        resourceName="linkItem"
      />
    </div>
  );
};

export default SidebarIncubateurComponent;
