import React, { useEffect } from "react";
import Profils from "components/Profils";
import Titre from "components/Titre";
import { Controller, useForm } from "react-hook-form";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { useUpdateEntrepreneurMutation } from "actions/auth/mutations";
import { toast } from "react-toastify";
import SubmitButton from "components/SubmitButton";
import Select from "react-select";
import useCurrentUser from "hooks/useCurrentUser";
import { stadeDevelop, statut } from "utils/PaysNState"
import { useGetProjetSecteurQuery } from "actions/projets/queries";
import { useGetIncubateurQuery, useGetEntrepreneurQuery } from "actions/auth/queries"
import useCountries from "hooks/useCountries";
import { useDispatch } from "react-redux";
import { updateUserSuccess } from "redux/features/userSliceWithTokenValidation"

const ProfilEntrepreneur = () => {
  const currentUser = useCurrentUser();
  const id = currentUser?.id;
  const { data: secteur } = useGetProjetSecteurQuery();
  const secteurs = secteur?.results;
  const { data: incubateurData } = useGetIncubateurQuery();
  const { data: EntrepreneurData } = useGetEntrepreneurQuery(id);
  const incubateurs = incubateurData?.results || [];
  const { countries, selectedCountry, setSelectedCountry } = useCountries(EntrepreneurData?.pays);
  const dispatch = useDispatch();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      "border": state.isFocused ? "1px solid var(--secondary-color)" : "1px solid #CACED8",
      "boxShadow": state.isFocused ? "0 0 0 2px var(--secondary-color)" : "none",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      "display": "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      "color": "#CACED8",
    }),
    singleValue: (provided) => ({
      ...provided,
      "color": "#333",
    }),
  };

  const [updateEntrepreneur, { isLoading: updateLoading }] = useUpdateEntrepreneurMutation();
  const { register, handleSubmit, setValue, control } = useForm();
  useEffect(() => {
    if (EntrepreneurData?.phone) {
      setValue("phone", EntrepreneurData.phone);
    }
  }, [EntrepreneurData, setValue]);
  useEffect(() => {
    if (selectedCountry) {
      setValue("pays", selectedCountry.label);
    }
  }, [selectedCountry, setValue]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("prenom", data.prenom || null);
  formData.append("nom", data.nom || null);
  formData.append("email", data.email || null);
  formData.append("secteur", parseInt(data.secteur) || null);
  formData.append("incubateur", parseInt(data.incubateur) || null);
  formData.append("etape_accompagment", data.etape_accompagment || null);
  formData.append("profession", data.profession || null);
  formData.append("statut", data.statut || null);
  formData.append("pays", data.pays || null);
  formData.append("ville", data.ville || null);
  formData.append("phone", data.phone || null);
    try {
      const response = await updateEntrepreneur({ id, body: formData });
      dispatch(updateUserSuccess(response));
      toast.success("Profil mis à jour avec succès !");
    } catch (err) {
      console.error("Échec de la mise à jour du profil:", err);
      toast.error("Échec de la mise à jour du profil.");
    }
  };

  return (
    <>
      <Titre titre="Mon Profil" bold={true}></Titre>
      <Profils
        colorTabs="gray"
        focusBorder={"focus:border-[var(--secondary-color)]"}
        couleurTab={"text-[var(--secondary-color)]"}
      >
        <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col sm:flex-col sm:w-full"
      >
        <div className="flex sm:flex-col gap-16 sm:gap-2">
          <div className="personnel w-1/2 sm:w-full">
            <Titre titre={"Personnel"} />
            <div className="flex gap-2 sm:flex-col mb-4">
              <div className="w-1/2 sm:w-full">
                <label
                  htmlFor="prenom"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                 Prénom
                </label>
                <input
                  {...register("prenom")}
                  defaultValue={EntrepreneurData?.prenom}
                  type="text"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                />
              </div>
              <div className="w-1/2 sm:w-full">
                <label
                  htmlFor="adresse"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                 Nom
                </label>
                <input
                        {...register("nom")}
                        type="text"
                        defaultValue={EntrepreneurData?.nom}
                        className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                      />
              </div>
            </div>
            <div className="mb-4">
              <label
                htmlFor="secteurA"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Secteur d'activité
              </label>
              <select
                {...register("secteur")}
                defaultValue={EntrepreneurData?.secteur || "--Sélectionner un secteur--"}
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
              >
                <option>--Sélectionner un secteur--</option>
                {
                    secteurs?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      )
                    })
                  }
              </select>
            </div>
            <div className="mb-4">
              <label
                htmlFor="etapeA"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Étape de l'accompagnement
              </label>
              <select
                {...register("etape_accompagment")}
                defaultValue={EntrepreneurData?.etape_accompagment || "--Sélectionner une étape--"}
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
              >
                <option>--Sélectionner une étape--</option>
                {
                      stadeDevelop?.map((item) => {
                        return (
                          <option key={item.id} value={item.value}>{item.name}</option>
                        )
                      })
                    }
              </select>
            </div>
            <div className="flex gap-2 sm:flex-col mb-4">
                    <div className="w-1/2 sm:w-full">
                      <label
                        htmlFor="prenom"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Profession
                      </label>
                      <input
                        {...register("profession")}
                        defaultValue={EntrepreneurData?.profession || "Non défini"}
                        type="text"
                        className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                      />
                    </div>
                    <div className="w-1/2 sm:w-full">
                      <label
                        htmlFor="adresse"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Nom de l'incubateur
                      </label>
                      <select
                {...register("incubateur")}
                defaultValue={EntrepreneurData?.incubateur}
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
              >
                <option>--Sélectionner--</option>
                {
                    incubateurs?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.nom_incubateur || "Indéfini"}</option>
                      )
                    })
                  }
              </select>
                    </div>
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="secteurA"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Status de l'entreprise
                    </label>
                    <select
                      {...register("statut")}
                      defaultValue={EntrepreneurData?.statut}
                      className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                    >
                      <option>--Sélectionner un secteur--</option>
                {
                    statut?.map((item) => {
                      return (
                        <option key={item.id} value={item.value}>{item.name}</option>
                      )
                    })
                  }
                    </select>
                  </div>
          </div>
          <div className="contact w-1/2 sm:w-full">
            <Titre titre={"Contact"} />
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Adresse email
              </label>
              <input
                {...register("email")}
                defaultValue={EntrepreneurData?.email}
                type="email"
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                placeholder="example@gmail.com"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="pays"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Pays
              </label>
              <Select
  defaultValue={EntrepreneurData?.pays}
  {...register("pays")}
  value={selectedCountry}
  onChange={(selectedOption) => {
    setSelectedCountry(selectedOption);
    setValue("pays", selectedOption ? selectedOption.label : "chargement...");
  }}
  options={countries}
  isSearchable
  placeholder="Sélectionnez un pays"
  styles={customStyles}
/>
            </div>
            <div className="mb-4">
              <label
                htmlFor="ville"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Ville
              </label>
              <input
                {...register("ville")}
                defaultValue={EntrepreneurData?.ville}
                type="ville"
                className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                // placeholder="Ma ville"
              />
            </div>
          <div className="mb-4">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Numéro de téléphone
                  </label>
                  <div className="flex sm:flex-col items-center gap-3">
                    <div className="w-2/12 sm:w-full">
                      <select
                        value={selectedCountry?.value || "chargement"}
                        onChange={(e) => {
                          const selectedOption = countries.find(
                            (country) => country.value === e.target.value
                          );
                          setSelectedCountry(selectedOption || null);
                        }}
                        className="w-full px-1 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                      >
                        {countries.map((country) => (
                          <option key={country.value} value={country.value}>
                            {`${country.callingCode}`}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="w-10/12 sm:w-full">
                      {/* Champ pour le numéro de téléphone sans l'indicatif */}
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="number"
                            className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none focus:border-[var(--secondary-color)]"
                      defaultValue={EntrepreneurData?.phone}
                      value={field.value}
                            onChange={(e) => {
                              const phoneNumber = e.target.value.replace(+`${selectedCountry?.callingCode}`, "").trim();
                              field.onChange(phoneNumber);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
          </div>
        </div>
        <SubmitButton buttonText={"Enregistrer"} isLoading={updateLoading} />
      </form>
    </div>
      </Profils>
    </>
  );
};

export default ProfilEntrepreneur;