import React, { useState, useEffect } from "react";
import Projet from "components/Projet";
import Titre from "components/Titre";
import { useGetProjetByIncubateurIdQuery } from "../../../actions/projetIncubateur/queries";
import { getUser } from "../../../redux/features/userSliceWithTokenValidation";
import { useSelector } from "react-redux";
import { API_STORAGE } from "../../../routes/api/endpoints";
import FiltreProjet from "components/FiltreProjet";
import { useRefresh } from "context/RefreshContext";
import { Outlet } from "react-router-dom";
import Loader2 from "components/Loader/Loader2";

function Projets() {
  const userStatus = useSelector(getUser);
  const current_user = userStatus.data.id;
  const [activeTab, setActiveTab] = useState(0);
  const [projects, setProjects] = useState([]);

  const {
    data: projectsData,
    error,
    refetch,
    isLoading
  } = useGetProjetByIncubateurIdQuery(current_user);
  const { refresh, resetRefresh } = useRefresh();

  useEffect(() => {
    if (projectsData) {
      setProjects(projectsData.results);
    }
  }, [projectsData]);

  useEffect(() => {
    if (refresh) {
      refetch();
      resetRefresh();
    }
  }, [refresh, refetch, resetRefresh]);

  const filterProjects = (projects, activeTab) => {
    switch (activeTab) {
      case 0:
        return projects; // Show all
      case 1:
        return projects?.filter((project) => project.statut === "valider");
      case 2:
        return projects?.filter((project) => project.statut === "rejeter");
      case 3:
        return projects?.filter((project) => project.statut === "nouveau");
      case 4:
        return projects?.filter((project) => project.statut === "suspendu");
      case 5:
        return projects?.filter((project) => project.statut === "terminer");
      default:
        return projects;
    }
  };

  const filteredProjects = filterProjects(projects, activeTab);

  if (error) {
    return <div>Une erreur s'est produite veuillez revenir plus tard.</div>;
  }

  return (
    <div>
      <Titre titre={"Nos projets"} />
      <FiltreProjet
        onChange={(tab) => setActiveTab(tab)}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 p-2 mt-5">
        {isLoading ? (
          <Loader2 />
        ) : error ? (
          <div>Une erreur s'est produite veuillez revenir plus tard.</div>
        ) : filteredProjects?.length > 0 ? (
          filteredProjects.map((item) => (
            <Projet
              key={item.id}
              Link={`/projets/${item.id}`}
              Video={API_STORAGE + item?.video}
              Secteur={item?.secteur}
              Statut={item?.statut}
              Titre={item?.title}
              Financement={item?.montant}
              Montant={item?.montant_part}
              className={"flex justify-end items-center w-full"}
            />
          ))
        ) : (
          <div>Aucun projet disponible.</div>
        )}

        <Outlet />
      </div>
    </div>
  );
}

export default Projets;