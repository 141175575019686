import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import useCurrentUser from "hooks/useCurrentUser";
import { ToastContainer, toast } from "react-toastify";
import { usePostRapportByEntrepreneurMutation } from "actions/rapport/mutations";
import { useGetProjetByEntrepreneurQuery } from "actions/projets/queries";
import { FiLoader } from "react-icons/fi";
import { stade } from "utils/PaysNState";
import DocumentAJoindre from "../../../components/DragDropFiles";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr";
import useNumberFormatter from "hooks/useNumberFormatter";

registerLocale("fr", fr);

function AjoutRapport({ onClick, initialCreatedAt }) {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  const { data: projects } = useGetProjetByEntrepreneurQuery(id);
  const projectsEntrepreneur = projects?.results || [];
  const {
    register,
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    if (initialCreatedAt) {
      setDate(new Date(initialCreatedAt));
      setValue("created_at", new Date(initialCreatedAt));
    }
  }, [initialCreatedAt, setValue]);
  const [postRapport, { isLoading: postLoading }] =
    usePostRapportByEntrepreneurMutation();
  const { formattedValues, getNumericValue, handleChange } = useNumberFormatter();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const onSubmit = async (data) => {
    setIsButtonDisabled(true);
    const formData = new FormData();
    console.log({ formData });
    formData.append("file", data.file[0]);
    formData.append("created_at", data.created_at);
    formData.append("description", data.description);
    formData.append("name", data.name);
    formData.append("chiffres_daffaire", getNumericValue(formattedValues.chiffres_daffaire));
    formData.append("montant_depense", getNumericValue(formattedValues.montant_depense));
    formData.append("nombres_clients", getNumericValue(formattedValues.nombres_clients));
    formData.append("nombres_employes", getNumericValue(formattedValues.nombres_employes));
    formData.append("statut", data.statut);
    formData.append("projet", data.projet);
    try {
      const rapportResponse = await postRapport({
        id,
        body: formData,
      }).unwrap();
      if (rapportResponse.error) {
        console.error("Error creating rapport:", rapportResponse.error);
        toast.error("Une erreur est survenue lors de l'ajout du rapport.");
      } else {
        toast.success("Votre rapport a été enregistré avec succès.");
        reset();
        onClick();
      }
    } catch (error) {
      console.error(
        "Une erreur inattendue est survenue lors de l'ajout du rapport:",
        error
      );
      toast.error(
        "Une erreur inattendue est survenue lors de l'ajout du rapport."
      );
    } finally {
      setIsButtonDisabled(false); // Réactivez le bouton à la fin de l'opération
    }
  };

  return (
    <div>
      <ToastContainer className={"mt-10"} />
      <div className="add-userAdmin-form-container">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div className="px-5">
            <div className="grid grid-cols-2 gap-12 items-center sm:block">
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="secteur"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Rapport du mois de:
                </label>
                <Controller
                  control={control}
                  name="created_at"
                  // rules={{ required: "La date est requise" }}
                  render={({ field }) => (
                    <DatePicker
                      selected={date}
                      onChange={(date) => {
                        setDate(date);
                        field.onChange(date);
                      }}
                      locale="fr"
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker={false}
                      className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm bg-white text-[var(--quaterioTextColor)]"
                    />
                  )}
                />
                {errors.created_at && (
                  <p className="text-red-500">{errors.created_at.message}</p>
                )}
              </div>
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="montant"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nombre de clients
                </label>
                <input
                  {...register("nombres_clients", {
                    required: "Le champ est requis",
                  })}
                  type="text"
                  placeholder="25"
                  value={formattedValues.nombres_clients || ""}
                  onChange={(e) => handleChange("nombres_clients", e.target.value)}
                  name="nombres_clients"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                />
                {errors.nombres_clients && (
                  <p className="text-red-500">
                    {errors.nombres_clients.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-12 items-center sm:block">
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="montant"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nombre d'employés
                </label>
                <input
                  placeholder="25"
                  {...register("nombres_employes", {
                    required: "Le champ est requis",
                  })}
                  value={formattedValues.nombres_employes || ""}
                  onChange={(e) => handleChange("nombres_employes", e.target.value)}
                  type="text"
                  name="nombres_employes"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                />
                {errors.nombres_employes && (
                  <p className="text-red-500">
                    {errors.nombres_employes.message}
                  </p>
                )}
              </div>
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="url"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Chiffre d'affaires
                </label>
                <input
                  placeholder="6.000.000 FCFA"
                  {...register("chiffres_daffaire", {
                    required: "Le champ est requis",
                  })}
                  type="text"
                  value={formattedValues.chiffres_daffaire || ""}
                  onChange={(e) => handleChange("chiffres_daffaire", e.target.value)}
                  id="chiffres_daffaire"
                  name="chiffres_daffaire"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                />
                {errors.chiffres_daffaire && (
                  <p className="text-red-500">
                    {errors.chiffres_daffaire.message}
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-12 items-center sm:block">
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="nom"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Dépenses
                </label>
                <input
                  {...register("montant_depense", {
                    required: "Le montant est requis",
                  })}
                  value={formattedValues.montant_depense || ""}
                  onChange={(e) => handleChange("montant_depense", e.target.value)}
                  type="text"
                  placeholder="4.000.000 FCFA"
                  name="montant_depense"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                />
                {errors.montant_depense && (
                  <p className="text-red-500">
                    {errors.montant_depense.message}
                  </p>
                )}
              </div>
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="secteur"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Stade
                </label>
                <select
                  name="statut"
                  id="statut"
                  {...register("statut", { required: "Ce champ est requis" })}
                  className={
                    "w-full px-3 py-2 bg-white border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                  }
                >
                  <option value="">Sélectionner</option>
                  {stade?.map((item) => {
                    return (
                      <option key={item.id} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
                {errors.statut && (
                  <p className="text-red-500">{errors.statut.message}</p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="secteur"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Projet:
                </label>
                <select
                  name="projet"
                  id="projet"
                  {...register("projet", { required: "Le projet est requis" })}
                  className={
                    "w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm bg-white text-[var(--quaterioTextColor)]"
                  }
                >
                  <option value="">Sélectionner un projet</option>
                  {projectsEntrepreneur?.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    );
                  })}
                </select>
                {errors.projet && (
                  <p className="text-red-500">{errors.projet.message}</p>
                )}
              </div>
            </div>
            <div>
              <DocumentAJoindre
                name={"file"}
                register={register}
                required="Ce champ est requis"
                setValue={setValue}
              />
              {errors.file && (
                <p className="text-red-500">{errors.file.message}</p>
              )}
            </div>
            <div className="mb-2 sm:mb-1">
              <label
                htmlFor={"description"}
                className="text-sm font-medium mb-2 sm:mb-1 text-[var(--nonaryTextColor)]"
              >
                Commentaires
              </label>
              <textarea
                id={"description"}
                name={"description"}
                rows="3"
                cols="30"
                placeholder={"Ajoutez un commentaire"}
                {...register("description", {
                  required: "La description est requise",
                })}
                className={
                  "w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                }
              ></textarea>
              {errors.description && (
                <p className="text-red-500">{errors.description.message}</p>
              )}
            </div>
          </div>
          <div className="flex items-center justify-start sm:py-0 py-4 px-4 gap-4 rounded-b">
            <button
              className="text-[var(--secondary-color)] border border-[var(--secondary-color)] rounded-lg
                      font-medium px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={onClick}
            >
              Annuler
            </button>
            <button
              type="submit"
              data-testid="btnId"
              className={`text-white border border-[var(--secondary-color)] rounded-lg
                bg-[var(--secondary-color)] font-medium px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1
                ${isButtonDisabled ? "cursor-not-allowed" : ""}`}>
              {postLoading ? (
                <div className="flex items-center gap-1">
                  En cours
                  <FiLoader className="text-white text-xl animate-spin" />
                </div>
              ) : (
                "Enregistrer"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AjoutRapport;
