import { ApiMananger } from "actions/rtkquery";
import { GET_RAPPORT, GET_RAPPORT_BY_ENTREPRENEUR, LIST_RAPPORT_BY_INCUBATEUR
 } from "../../routes/api/endpoints"
const projetsApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getRapportId: build.query({
            query: (id) => `${GET_RAPPORT_BY_ENTREPRENEUR}${id}`,
            providesTags: ["INVALIDATE_Projets", "rapport"],
        }),
        getRapport: build.query({
            query: () => GET_RAPPORT,
            providesTags: ["INVALIDATE_Projets", "rapport"],
        }),
        getListRapportId: build.query({
            query: (id) => `${LIST_RAPPORT_BY_INCUBATEUR}${id}`,
            providesTags: ["INVALIDATE_Projets", "rapport"],
        }),
    }),
});

export const {
    useGetRapportIdQuery,
    useGetRapportQuery,
    useGetListRapportIdQuery
} = projetsApi;