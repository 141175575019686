export const API = process.env.REACT_APP_API_URL;
export const API_STORAGE = process.env.REACT_APP_API_IMG_URL;

export const LOGIN_ROUTE = `${API}auth/login/`;
export const REFRESH_TOKEN = `${API}auth/refresh-token/`;
export const NOTIFICATION = `${API}notification_abonnes_incubateur/`;

// Endpoint Incubateur
export const GET_PROJET_ENTREPRENEUR_BY_INCUBATEUR = `${API}projetbyincubateur/`;
export const GET_PROJETS = `${API}projet/`;
export const POST_PROJET = `${API}projet/`;
export const POST_PROJET_BY_ENTREPRENEUR = `${API}projetbyentrepreneur/`;
export const GET_PROJET_BY_ENTREPRENEUR = `${API}projetbyentrepreneur/`;
export const GET_SUBSCRIBER = `${API}subscriber/`;
export const POST_SUBSCRIBER = `${API}subscriber/`;
export const DELETE_SUBSCRIBER = `${API}unsubscribeprojet/`;
export const GET_SECTEURS = `${API}secteurs/`;

// Users/GET et PUT
export const GET_USERS = `${API}user/`;
export const UPDATE_ROUTE = `${API}user/`;
export const GET_ENTREPRENEUR = `${API}entrepreneur/`;
export const UPDATE_ENTREPRENEUR = `${API}entrepreneur/`;
export const GET_INCUBATEURID = `${API}incubateur/`;
export const UPDATE_INCUBATEURID = `${API}incubateur/`;
export const UPDATE_PASSWORD = `${API}changepassword/`;

export const GET_PROJETS_SUBSCRIBER_INCUBATEUR = `${API}subscriber_incubateur_projet/`;
export const GET_PROJETS_AND_SECTEUR_BY_INCUBATEUR = `${API}projet_and_secteur_by_incubateur/`;
export const GET_PROJETS_AND_SECTEUR_BY_INCUBATEUR_STATIQUE = `${API}projet_and_secteur_by_incubateur_statistique/`;
export const GET_SECTEUR_BY_INVESTISSEUR_STATISTIQUE = `${API}secteurs_statistique_investissement/`;
export const GET_PROJET_INVESTISSEUR_FINANCER = `${API}investss/`;
export const POST_DOCUMENT = `${API}document/`;
export const PUT_DOCUMENT = `${API}document/`;
export const POST_RAPPORT = `${API}rapport/`;
export const GET_RAPPORT = `${API}rapport/`;
export const GET_RAPPORT_BY_ENTREPRENEUR = `${API}rapportbyporteur/`;
export const POST_RAPPORT_BY_ENTREPRENEUR = `${API}rapportbyporteur/`;
export const GET_PROJET_SOUSCRIT_BY_ENTREPRENEUR = `${API}subscriber_entrepreneur_projet/`;
export const GET_STATISTIQUE_PROJET_ENTREPRENEUR = `${API}projetbypentrepreneur_statistique_invest/`;
export const LIST_ENTREPRENEUR_BY_INCUBATEUR = `${API}liste_entrepreneur_by_incubateur/`
export const LIST_PROJET_SOUSCRIT_BY_INCUBATEUR = `${API}subscriber_incubateur_projet/`
export const LIST_RAPPORT_BY_INCUBATEUR = `${API}rapportbyProjetEntrepreneur_incubateur/`
export const LIST_PROJET_FINANCE_BY_INCUBATEUR = `${API}projet_invest_entrepreneur_incubateur/`;
export const GET_INCUBATEUR = `${API}create_incubateur/`;