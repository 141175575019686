import React from "react";
import styled from "styled-components";

function Titre({ titre, bold, size, mB }) {
  const Title = styled.p`
    font-weight: ${bold ? "bold" : "500"};
    font-size: ${size ? "25px" : "15px"};
    font-family: 'Montserrat';
    color: var(--nonaryTextColor);
    padding: 16px 8px;
    margin-bottom: ${mB ? "mB" : "0.75rem"};
  `;

  return (
    <Title>{titre}</Title>
  );
}

export default Titre;
