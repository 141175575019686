import React, { useState } from "react";
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
} from "react-icons/fa";
import Profile from "../assets/Images/Ellipse 381.png";
import { NavLink, useParams } from "react-router-dom";
import { IconWithDropdown } from "./Details";
import Titre from "./Titre";
import DetailFinance from "./DetailFinance";
import LesInvestisseurs from "./LesInvestisseurs";
import ModalDetails from "./ModalDetails";
import { useGetProjetsQuery } from "actions/projets/queries";
import Loader from "components/Loader/Loader";
import ReseauSocial from "./ReseauSocial";
import ReactPlayer from "react-player";
import ImageVideo from "assets/Images/videoImage.png";
import { FiLoader } from "react-icons/fi";
import ActionProjetIncubateur from "./ActionProjetIncubateur";

const ProjetDetail = ({
  video,
  title,
  description,
  data,
  prenom,
  nom,
  souscrireData,
  subtitleClass,
  Link,
  disabledValider,
  disabledRejeter,
  disabledSuspendre,
  projet,
  onClick1,
  onClick2,
  onClick3,
  flex,
  hidden,
  Interreser,
  onClickInteresser,
  isLoading,
  background,
  reseau,
  flexEntrepreneur,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleOpenModal = () => setIsOpenModal(!isOpenModal);

  const { id } = useParams();
  const { data: projectsData } = useGetProjetsQuery(id);

  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="flex items-center gap-2 p-2">
        <NavLink to={Link}>
          <FaLongArrowAltLeft size={20} />
        </NavLink>
        <Titre titre="Détails du projet" />
      </div>
      <div className="flex items-start sm:flex-col gap-3">
        <div className="w-7/12 sm:w-full p-2">
          <div>
            {video ? (
              <ReactPlayer
                className="w-[100%] h-[50vh]"
                url={video}
                playing={!!video}
                controls
              />
            ) : (
              <div className="no-video">
                <img src={ImageVideo} alt="Vidéo par défaut" className="w-full" />
              </div>
            )}
          </div>
          <div className="flex sm:flex-col sm:items-start sm:gap-2 items-center justify-between mt-2">
            <h3 className="font-bold">{title}</h3>
            <IconWithDropdown data={souscrireData} />
          </div>
          <div className="p-3">
            <div className="border-b-2 border-gray-300 p-2 sm:p-1">
              <Titre titre="Description du projet" />
            </div>
            <div className="p-4 sm:p-0">
              <p>{description}</p>
            </div>
          </div>
        </div>
        <div className="w-5/12 sm:w-full">
          <DetailFinance data={data} subtitleClass={subtitleClass} />
          <div className="flex flex-col items-center">
            <ActionProjetIncubateur
            disabledValider={disabledValider}
            disabledRejeter={disabledRejeter}
            disabledSuspendre={disabledSuspendre}
            projet={projet}
            onClick1={onClick1}
            onClick2={onClick2}
            onClick3={onClick3}
            flex={flex}
            isLoading={isLoading}
            />
            <div className={`${hidden} mb-24`}>
              <button
                className={`text-white w-96 items-center rounded-md px-4 py-2 ${Interreser !== 0 ? "bg-[var(--secondaryClair-color)]" : "bg-[var(--secondary-color)]"
                  }`}
                onClick={onClickInteresser}
              >
                {isLoading && isLoading ? (
                  <>
                    {Interreser !== 0 ? "Déjà intéressé" : "Je suis intéressé"}
                    <FiLoader className="text-white text-xl animate-spin" />
                  </>
                ) : (
                  Interreser !== 0 ? "Déjà intéressé" : "Je suis intéressé"
                )}
              </button>
            </div>
            <div className="sm:mt-0 lg:mt-0">
              <div className="flex flex-col gap-2">
                <LesInvestisseurs />
              </div>
            </div>
            <div className={`${flexEntrepreneur} mt-16 sm:mt-4 lg:mt-4 flex flex-col justify-center items-center gap-2`}>
              <p className="font-bold text-nowrap">Profil du porteur de projet:</p>
              <div className="flex flex-col gap-2">
                <div className="flex justify-center">
                  <img src={Profile} alt="Profile" />
                </div>
                <p className="font-bold text-center">{prenom} {nom}</p>
              </div>
              <div className="sm:text-[0.7rem] text-start px-5">
                {prenom} {nom} est un soutien précieux pour les nouvelles entreprises. Il aide les entrepreneurs à transformer leurs idées en succès en leur offrant conseils et ressources.
              </div>
              <div className="flex gap-4 mt-3">
                {reseau?.map((item) => (
                  <ReseauSocial
                    key={item.id}
                    icon={item.icon}
                    link={item.link}
                    background={background}
                  />
                ))}
              </div>
              <button className="mt-4" onClick={handleOpenModal}>
                <div className="flex items-center gap-2 text-nowrap rounded-md text-white p-3 w-[160px] mb-3" style={{ background: background || "var(--secondary-color)" }}>
                  <span>En savoir plus...</span>
                  <span>
                    <FaLongArrowAltRight />
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {isOpenModal && (
        <ModalDetails
          prenom={projectsData?.user?.prenom}
          profession={projectsData?.user?.profession}
          pays={projectsData?.user?.pays}
          secteurA={projectsData?.user?.secteurA}
          langue={projectsData?.user?.langue}
          email={projectsData?.user?.email}
          phone={projectsData?.user?.phone}
        />
      )}
    </div>
  );
};

export default ProjetDetail;
