import React from "react"
import "./Loader2.css"

const Loader2 = () => {
  return (
    <div>
        <div id="loading-bar-spinner" className="spinner">
        <div className="spinner-icon"></div></div>
    </div>
  )
}

export default Loader2
