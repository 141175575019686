import { ApiMananger } from "actions/rtkquery";
import { GET_PROJETS, GET_SECTEUR_BY_INVESTISSEUR_STATISTIQUE,
    GET_PROJET_INVESTISSEUR_FINANCER, GET_PROJET_BY_ENTREPRENEUR, GET_SECTEURS,
    GET_STATISTIQUE_PROJET_ENTREPRENEUR, GET_PROJET_SOUSCRIT_BY_ENTREPRENEUR,
    LIST_PROJET_FINANCE_BY_INCUBATEUR
 } from "../../routes/api/endpoints"
const projetsApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getProjets: build.query({
            query: (id) => `${GET_PROJETS}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjet: build.query({
            query: () => GET_PROJETS,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetSecteurByInvestisseurStatistiqueId: build.query({
            query: (id) => `${GET_SECTEUR_BY_INVESTISSEUR_STATISTIQUE}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetFinancerByInvestisseur: build.query({
            query: (id) => `${GET_PROJET_INVESTISSEUR_FINANCER}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetByEntrepreneur: build.query({
            query: (id) => `${GET_PROJET_BY_ENTREPRENEUR}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetSecteur: build.query({
            query: () => GET_SECTEURS,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetStatistiqueByEntrepreneur: build.query({
            query: (id) => `${GET_STATISTIQUE_PROJET_ENTREPRENEUR}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetSouscritByEntrepreneur: build.query({
            query: (id) => `${GET_PROJET_SOUSCRIT_BY_ENTREPRENEUR}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getListProjetFinanceByIncubateur: build.query({
            query: (id) => `${LIST_PROJET_FINANCE_BY_INCUBATEUR}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
    }),
});

export const {
    useGetProjetsQuery, useGetProjetQuery, useGetProjetSecteurQuery,
    useGetProjetSecteurByInvestisseurStatistiqueIdQuery,
    useGetProjetFinancerByInvestisseurQuery,
    useGetProjetByEntrepreneurQuery,
    useGetProjetStatistiqueByEntrepreneurQuery,
    useGetProjetSouscritByEntrepreneurQuery,
    useGetListProjetFinanceByIncubateurQuery
} = projetsApi;