import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
// import PageNotFound from "pages/NotFound/PageNotFound";
import {
  privateRoutesIncubateur,
  privateRoutesInvestisseur,
  privateRoutesEntrepreneur,
  privateRoutesAdmin,
} from "./navigation/navigationRoutes/private";
import { publicRoutes } from "./navigation/navigationRoutes/public";
// import { LOGIN_PATH, NOTFOUND_PATH } from "./navigation/navigationPaths";
import useIsAuthenticate from "hooks/useIsAuthenticatedUser";
// import LogIn from "pages/Authentication/LogIn";
import { useSelector } from "react-redux";
import { getUser } from "redux/features/userSliceWithTokenValidation";
// import Accueil from "pages/PartieLandingPage/Accueil/Accueil";

const privateRouterByUser = (status) => {
  if (status === "incubateur") {
    return privateRoutesIncubateur;
  } else if (status === "investisseur") {
    return privateRoutesInvestisseur;
  } else if (status === "entrepreneur") {
    return privateRoutesEntrepreneur;
  } else {
    return privateRoutesAdmin;
  }
};

/*
|--------------------------------------------------------------------------
| Web Routes Rendering
|--------------------------------------------------------------------------
|
| Ici on rend les routes private ou public en fonction de isAuthenticated
| La manière dont react-router fonctionne, on ne doit pas rendre les routes
| public si on est authentifié et vice-versa, afin d'éviter certaines erreurs
| de redirections
|
*/
function Roots() {
  const user = useSelector(getUser);
  const userRole = user?.data?.user_type;
  const isAuthenticated = useIsAuthenticate();

  const globalPrivateRoute = [
    ...privateRouterByUser(userRole),
    {
      path: "",
      element: "<Accueil/> private accueil",
    },
  ]

  const router = createBrowserRouter([
    ...(isAuthenticated ? globalPrivateRoute : publicRoutes),
  ]);

  // const routers = isAuthenticated
  //   ? userRole === "incubateur"
  //     ? createBrowserRouter([
  //         ...privateRoutesIncubateur,
  //         {
  //           path: LOGIN_PATH,
  //           element: <LogIn />,
  //         },
  //       ])
  //     : userRole === "investisseur"
  //     ? createBrowserRouter([
  //         ...privateRoutesInvestisseur,
  //         {
  //           path: LOGIN_PATH,
  //           element: <LogIn />,
  //         },
  //       ])
  //     : userRole === "entrepreneur"
  //     ? createBrowserRouter([
  //         ...privateRoutesEntrepreneur,
  //         {
  //           path: LOGIN_PATH,
  //           element: <LogIn />,
  //         },
  //       ])
  //     : createBrowserRouter([
  //         ...privateRoutesAdmin,
  //         {
  //           path: LOGIN_PATH,
  //           element: <LogIn />,
  //         },
  //       ])
  //   : createBrowserRouter([
  //       ...publicRoutes,
  //       {
  //         path: NOTFOUND_PATH,
  //         element: <PageNotFound />,
  //       },
  //     ]);

  return (
    <RouterProvider router={router} fallbackElement={<p>Chargement...</p>} />
  );
}

export default Roots;


