import React from "react";

const Legend = ({ display, labels = [], colors = [], percentages = [], showPercentages = false, bgColor, padding,
money
 }) => {
  return (
    <div style={{
      display: display,
      flexDirection: "column",
      gap: "0.75rem",
      alignItems: "flex-start",
      marginTop: "10px",
      background: bgColor || "",
      padding: padding || "0",
      borderRadius: "8px",
    }}>
      {labels.map((label, index) => (
        <div className="lg:m-0 "
         key={index} style={{ display: "flex", alignItems: "center", margin: "5px" }}>
          <div style={{
            width: "15px",
            height: "15px",
            borderRadius: "5px",
            backgroundColor: colors[index] || "#000",
            marginRight: "5px"
          }}></div>
          <span className="">
            {label}
            {showPercentages && (
              <span className="sm:text-sm md:text-sm  lg:text-xs md:text-nowrap" style={{ fontSize: "12px", color: "gray", marginLeft: "4px" }}>
                {` - ${percentages[index]}`} {money}
              </span>
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default Legend;
