import { ApiMananger } from "actions/rtkquery";
import { GET_PROJET_ENTREPRENEUR_BY_INCUBATEUR,
    GET_PROJETS_AND_SECTEUR_BY_INCUBATEUR,
    GET_PROJETS_AND_SECTEUR_BY_INCUBATEUR_STATIQUE,
    LIST_PROJET_SOUSCRIT_BY_INCUBATEUR
 } from "../../routes/api/endpoints";

const projetByIncubateurApi = ApiMananger.injectEndpoints({
    endpoints: (build) => ({
        getProjetByIncubateurId: build.query({
            query: (id) => `${GET_PROJET_ENTREPRENEUR_BY_INCUBATEUR}${id}/`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetAndSecteurByIncubateurId: build.query({
            query: (id) => `${GET_PROJETS_AND_SECTEUR_BY_INCUBATEUR}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetAndSecteurByIncubateurStatiqueId: build.query({
            query: (id) => `${GET_PROJETS_AND_SECTEUR_BY_INCUBATEUR_STATIQUE}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
        getProjetSouscritByIncubateur: build.query({
            query: (id) => `${LIST_PROJET_SOUSCRIT_BY_INCUBATEUR}${id}`,
            providesTags: ["INVALIDATE_Projets"],
        }),
    }),
})

export const {
    useGetProjetByIncubateurIdQuery,
    useGetProjetAndSecteurByIncubateurIdQuery,
    useGetProjetAndSecteurByIncubateurStatiqueIdQuery,
    useGetProjetSouscritByIncubateurQuery
} = projetByIncubateurApi;