import TableComponent from "components/TableComponents";
import Titre from "components/Titre";
import React from "react";
import styled from "styled-components";
import { useGetListEntrepreneurByIncubateurQuery } from "actions/auth/queries";
import Loader2 from "components/Loader/Loader2";
import useCurrentUser from "hooks/useCurrentUser";

// Styles pour le tableau
const RapportTable = styled.div`
  thead {
    background-color: #F3F7FF;
    color: #369e8a;
  }
`;

const PorteursProjets = () => {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  const { data: users, isLoading } = useGetListEntrepreneurByIncubateurQuery(id);
  const filteredUsers = users?.results || [];
  console.log({ filteredUsers });
  const data = filteredUsers?.map((user) => ({
    Prénom: user.prenom || "Non défini",
    Nom: user.nom || "Non défini",
    NomProjet: user.title || "Non défini",
    SecteursAct: "Pas de secteur",
    Montants: `${user.montant || "0"} FCFA`,
  }));

  const columns = [
    { id: 1, header: "Prénom", accessor: "Prénom" },
    { id: 2, header: "Nom", accessor: "Nom" },
    { id: 3, header: "Nom du Projet", accessor: "NomProjet" },
    { id: 4, header: "Secteurs d'Activité", accessor: "SecteursAct" },
    { id: 5, header: "Montant Élevé", accessor: "Montants" },
  ];

  return (
    <div className="bg-white h-[90vh] rounded-md">
      <Titre titre={"Liste des Porteurs de projets"} bold={true} />
      {isLoading ? (
        <Loader2 />
      ) : data && data.length > 0 ? (
        <div className="bg-white rounded-lg relative left-7">
        <RapportTable>
        <TableComponent data={data} columns={columns} />
        </RapportTable>
        </div>
      ) : (
        <div className="">Pas de données</div>
      )}
    </div>
  );
};

export default PorteursProjets;
