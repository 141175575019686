/* eslint-disable react/react-in-jsx-scope */
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import { LOGIN_PATH, REGISTER_PATH } from "../navigationPaths";
import WithoutAuth from "layouts/WithoutAuth";
import LogIn from "../../../../src/pages/Authentication/LogIn";
import Accueil from "pages/PartieLandingPage/Accueil/Accueil";
import { Navigate } from "react-router-dom"

/*
|---------------------------------------------------------------
| Les routes publics
|---------------------------------------------------------------
| Toute page qui ne necessite pas une authentification doit etre ici
|
*/
export const publicRoutes = [
    {
        path: "*",
        element: <Navigate to={"/login"} />,
      },
      {
        path: "",
        element: <Accueil/>,
      },
    {
        path: LOGIN_PATH,
        errorElement: <ErrorBoundary />,
        element: <WithoutAuth />,
        children: [
            {
                path: "",
                element: <LogIn/>,
            },
            {
                path: REGISTER_PATH,
                element: <p>Hello from register</p>
            }
        ]
    },
];