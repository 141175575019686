import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { FaRegTimesCircle } from "react-icons/fa";
import { WiTime6, WiTime4 } from "react-icons/wi";
import { SiWakatime } from "react-icons/si";
import { TbEyePlus } from "react-icons/tb";
import { FiLoader } from "react-icons/fi";
import StatutProjet from "./StatutProjet";
import ImageVideo from "assets/Images/videoImage.png";
import ReactPlayer from "react-player";
import formatNumber from "utils/FormatNumber";

const SecteurContainer = styled.div`
  color: var(--primaryColor);
  font-weight: 700;
  font-size: 10.3px;
`;

const StatutContainer = styled.div`
  color: var(--primaryColor);
  font-weight: 500;
  font-size: 9.14px;
`;

const TitreContainer = styled.p`
  color: var(--quinaryColor);
  font-weight: 700;
  font-size: 18.02px;
`;

const BesoinMontantContainer = styled.span`
  color: var(--septanarioTextColor);
  font-weight: 700;
  font-size: 12.6px;
`;

const FinancementContainer = styled.p`
  color: var(--backgroundOrange);
  font-weight: 700;
  font-size: 12.6px;
`;

const MontantContainer = styled.p`
  color: var(--secondary-color);
  font-weight: 700;
  font-size: 12.6px;
`;

function Projet({
  Video,
  Secteur,
  Statut,
  Titre,
  Financement,
  Montant,
  className,
  Link,
  bgColor,
  classInteresser,
  Interreser,
  isLoading, // Add isLoading prop
}) {
  return (
    <div className="h-full">
      <NavLink to={Link} className="flex justify-center h-full ">
        <div className="bg-white p-2 flex flex-col justify-between items-center w-full h-full">
          <div className="project-item-card-img-container">
            {Video ? (
              <ReactPlayer
                className="w-[100%] h-[100%]"
                url={Video}
                controls={true}
              />
            ) : (
              <div className="no-video">
                <img src={ImageVideo} alt="Vidéo par défaut" className="w-full" />
              </div>
            )}
          </div>
          <div className="w-full h-full flex flex-col justify-between pt-6">
            <div className="flex justify-between items-center">
              <div className="flex justify-between w-full">
                <SecteurContainer className="w-full my-2">
                  <span
                    style={{
                      background: bgColor || "var(--backgroundOrange)",
                      padding: "5px 10px",
                    }}
                  >
                    {Secteur?.length ? (
                      Secteur.map((item, index) => (
                        <span key={item.id} className="p-2">
                          {item.name}
                          {index < Secteur.length - 1 ? " - " : ""}
                        </span>
                      ))
                    ) : (
                      "Indéfini"
                    )}
                  </span>
                </SecteurContainer>
                <StatutContainer className={className}>
                  {Statut === "valider" ? (
                    <StatutProjet
                      Icon={<WiTime4 className="text-white text-sm font-bold" />}
                      NomStatut={"En cours"}
                      background={"var(--denaryTextColor)"}
                    />
                  ) : Statut === "rejeter" ? (
                    <StatutProjet
                      Icon={<FaRegTimesCircle className="text-white text-sm font-bold" />}
                      NomStatut={"Rejeté"}
                      background={"var(--quinaryBorderColor)"}
                    />
                  ) : Statut === "suspendu" ? (
                    <StatutProjet
                      Icon={<WiTime6 className="text-white text-sm font-bold" />}
                      NomStatut={"Suspendu"}
                      background={"var(--quinaryBorderColor)"}
                    />
                  ) : Statut === "terminer" ? (
                    <StatutProjet
                      Icon={<SiWakatime className="text-white text-sm font-bold" />}
                      NomStatut={"Terminer"}
                      background={"var(--secondary-color)"}
                    />
                  ) : (
                    <StatutProjet
                      Icon={<WiTime4 className="text-white text-sm font-bold" />}
                      NomStatut={"En attente"}
                      background={"var(--exaColor)"}
                    />
                  )}
                </StatutContainer>
                <StatutContainer style={{ display: classInteresser || "none" }}>
                  <button
                    type="submit"
                    className={`w-28 border rounded border-[#369E8A] ${Interreser !== 0 ? "text-[#369E8A]" : "text-[var(--secondary-color)] border-[var(--secondary-color)]"}`}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <FiLoader className="text-white text-xl animate-spin" />
                    ) : (
                      <StatutProjet
                        Icon={<TbEyePlus className={`text-sm font-bold ${Interreser !== 0 ? "text-[#369E8A]" : "text-[var(--secondary-color)]"}`} />}
                        NomStatut={Interreser !== 0 ? "Déjà intéressé" : "Intéresser"}
                      />
                    )}
                  </button>
                </StatutContainer>
              </div>
            </div>
            <div className="py-4">
              <TitreContainer className="pb-1">{Titre || "Pas de titre"}</TitreContainer>
              <div className="flex justify-between items-center">
                <div className="financement">
                  <BesoinMontantContainer>
                    Besoin en financement:
                  </BesoinMontantContainer>
                  <FinancementContainer className="ContainMontant mt-1">
                    FCFA {formatNumber(Financement) || "0"}
                  </FinancementContainer>
                </div>
                <div className="financement">
                  <BesoinMontantContainer>Montant atteint:</BesoinMontantContainer>
                  <MontantContainer className="mt-1">
                    FCFA {formatNumber(Montant) || "0"}
                  </MontantContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavLink>
    </div>
  );
}

export default Projet;
