import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getUser } from "redux/features/userSliceWithTokenValidation";

const Tabs = ({ tabs }) => {
  const currentUser = useSelector(getUser);
  const isIncubateur = currentUser.data?.user_type === "incubateur";

  const [activeTab, setActiveTab] = useState(tabs[0]?.id);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  // Define active color based on user type
  const getActiveTabColor = (tabId) => {
    return activeTab === tabId ? (isIncubateur ? "#E87930" : "#369E8A") : "";
  };

  return (
    <div>
      <div className="flex sm:flex-col sm:text-nowrap sm:space-x-0 justify-center mt-8 space-x-12 md:justify-start md:space-x-40 lg:space-x-32 xl:space-x-80">
        {tabs.map((tab) => (
          <button
            key={tab?.id}
            className="py-2 px-4 cursor-pointer text-center md:text-lg"
            style={{ color: getActiveTabColor(tab.id) }}
            onClick={() => handleTabClick(tab?.id)}
          >
            {tab?.label}
          </button>
        ))}
      </div>
      <div className="mt-5 p-4">
        {tabs.map(
          (tab) =>
            activeTab === tab?.id && <div key={tab?.id}>{tab?.content}</div>
        )}
      </div>
    </div>
  );
};

export default Tabs;
