import React from "react";
import formatNumber from "utils/FormatNumber";

const DetailFinance = ({
  data,
  subtitleClass,
}) => {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 md:gap-0 lg:grid-cols lg:gap-3 sm:gap-0 mb-2">
       {
        data && data.map((item) => (
          <div className="flex flex-col m-3 sm:m-2 md:m-2" key={item.id}>
                <div className="">
                    <div className="flex items-center gap-1">
                        <p className="flex items-center">{item.icon}</p>
                        <p className="font-bold text-[12px] lg:text-[8px]">{item.title}</p>
                    </div>
                    <p className={`text-sm ps-5 ${subtitleClass}`}>{formatNumber(item.subtitle)}</p>
                </div>
            </div>
        ))
       }
     </div>
  );
};

export default DetailFinance;
