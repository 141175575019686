import React from "react";
import { logoBlanc } from "./LogoComponent";
import { NavLink } from "react-router-dom";

function LogoSlogan() {
  return (
    <div className="">
      <NavLink to="/" className="d-flex justify-content-center">
        <img src={logoBlanc} alt="logo" className="w-75" />
      </NavLink>
      {/* <p className="pt-4 text-center">LET’S BUILD TOGETHER THE NEXT AFRICA</p> */}
    </div>
  );
}

export default LogoSlogan;
