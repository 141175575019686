import React from "react";

function LesInvestisseurs() {
  return (
    <>
      <p className="font-bold break-normal text-nowrap">Les investisseurs:</p>
      <p className="text-nowrap sm:text-[0.6rem]">
        Pas de données
      </p>
    </>
  );
}

export default LesInvestisseurs;
