import React from "react";
import { IoClose } from "react-icons/io5";

function ModalNotification({ children, onOpenModal, onCloseModal, titre, marginTop, hover }) {
  return (
    <div
      aria-hidden="true"
      onClick={onOpenModal}
      className={`fixed overflow-y-auto inset-0 z-[1] ${marginTop} flex opacity-100 pointer-events-auto`}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5">
            <h3 className="text-lg text-sm font-medium text-[var(--nonaryTextColor)] dark:text-white">
              {titre}
            </h3>
            <button
              type="button"
              className={`text-gray-900 bg-transparent hover:bg-gray-900 hover:text-gray-200
          rounded-lg text-2xl ms-auto inline-flex justify-center items-center ${hover ? hover : "hover:bg-dark-500"} hover:text-white`}
              onClick={onCloseModal}
            >
              <IoClose className="" />
            </button>
          </div>
          <div className="p-4 md:p-5">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default ModalNotification;
