import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import {
  useGetProjetSecteurQuery
} from "actions/projets/queries";
import { usePostProjectByEntrepreneurMutation, usePostDocumentMutation } from "actions/projets/mutations";
import { FiLoader } from "react-icons/fi";
import { stadeDevelop, pays } from "utils/PaysNState"
import DocumentAJoindre from "../../../components/DragDropFiles"
import useCurrentUser from "hooks/useCurrentUser";
import useNumberFormatter from "hooks/useNumberFormatter";

function AjoutProjet({ onClick }) {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  console.log({ id });
  const { data: secteur } = useGetProjetSecteurQuery();
  const secteurs = secteur?.results;
  const [postDocument] = usePostDocumentMutation();
  const [postProjetByEntrepreneur, { isLoading: postLoading }] = usePostProjectByEntrepreneurMutation();
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const { formattedValues, getNumericValue, handleChange } = useNumberFormatter();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const onSubmit = async (data) => {
    setIsButtonDisabled(true);
    const formData = new FormData();
    formData.append("name", "fichier");
    formData.append("user", id);
    formData.append("doc", data.doc[0]);
    try {
      const documentResponse = await postDocument(formData);
      console.log({ documentResponse });
      const documentId = documentResponse.data?.id; // Assurez-vous que la réponse contient l'ID du document
      const projectData = {
        title: data.title,
        description: data.description,
        user: id,
        secteur: [parseInt(data.secteur)],
        montant: getNumericValue(formattedValues.montant),
        senegal: data.senegal,
        url_demo: data.url_demo,
        stade_development: data.stade_development,
        files: [parseInt(documentId)],
      };
      const projectResponse = await postProjetByEntrepreneur({
        id,
        body: projectData,
      }).unwrap();
      if (projectResponse.error) {
        console.error("Error creating project:", projectResponse.error);
        toast.error("Une erreur est survenue lors de l'ajout du projet.");
      } else {
        toast.success("Votre projet a été enregistré avec succès.");
        reset();
        onClick();
      }
    } catch (error) {
      console.error("Une erreur inattendue est survenue lors de l'ajout du projet:", error);
      toast.error("Une erreur inattendue est survenue lors de l'ajout du projet.");
    } finally {
      setIsButtonDisabled(false); // Réactivez le bouton à la fin de l'opéra
    }
  };

  return (
    <div>
      <ToastContainer className={"mt-10"} />
      <div className="add-userAdmin-form-container">
        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <div className="px-5">
            <div className="grid grid-cols-2 gap-12 items-center sm:block">
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="secteur"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Sélectionner un secteur
                </label>
                <select
                  name="secteur" id="secteur"
                  {...register("secteur", { required: "Le secteur est requis" })}
                  className={"w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm bg-white text-[var(--quaterioTextColor)]"}
                >
                  <option value="">Sélectionner un secteur</option>
                  {
                    secteurs?.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>{item.name}</option>
                      )
                    })
                  }
                </select>
                {errors.secteur && <p className="text-red-500">{errors.secteur.message}</p>}
              </div>
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="nom"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nom du projet
                </label>
                <input
                  {...register("title", { required: "Le nom du projet est requis" })}
                  type="text"
                  name="title"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                />
                {errors.title && <p className="text-red-500">{errors.title.message}</p>}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-12 items-center sm:block">
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="montant"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Besoin en financement
                </label>
                <input
                  {...register("montant", { required: "Le montant est requis" })}
                  type="text"
                  value={formattedValues.montant || ""}
                  onChange={(e) => handleChange("montant", e.target.value)}
                  name="montant"
                  className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                />
                {errors.montant && <p className="text-red-500">{errors.montant.message}</p>}
              </div>
              <div className="mb-2 sm:mb-1">
                <label
                  htmlFor="senegal"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Pays d'exécution
                </label>
                <select
                  name="senegal" id="senegal"
                  {...register("senegal", { required: "Le pays est requis" })}
                  className={"w-full px-3 py-2 bg-white border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"}
                >
                  <option value="">Sélectionner un pays</option>
                  {
                    pays?.map((item) => {
                      return (
                        <option key={item.id} value={item.value}>{item.name}</option>
                      )
                    })
                  }
                </select>
                {errors.senegal && <p className="text-red-500">{errors.senegal.message}</p>}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-12 items-center sm:block">
              <div className="">
                <div className="mb-2 sm:mb-1">
                  <label
                    htmlFor="url"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Ajoutez le lien de la vidéo
                  </label>
                  <input
                    {...register("url_demo", { required: "Le lien de la vidéo est requis" })}
                    type="url" id="url" name="url_demo"
                    className="w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"
                  />
                  {errors.url_demo && <p className="text-red-500">{errors.url_demo.message}</p>}
                </div>
                <div className="mb-2 sm:mb-1">
                  <label
                    htmlFor="secteur"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    State du projet
                  </label>
                  <select
                    name="stade_development" id="stade_development"
                    {...register("stade_development", { required: "Ce champ est requis" })}
                    className={"w-full px-3 py-2 bg-white border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"}
                  >
                    <option value="">Sélectionner</option>
                    {
                      stadeDevelop?.map((item) => {
                        return (
                          <option key={item.id} value={item.value}>{item.name}</option>
                        )
                      })
                    }
                  </select>
                  {errors.stade_development && <p className="text-red-500">{errors.stade_development.message}</p>}
                </div>
              </div>
              <div>
            <DocumentAJoindre name={"doc"} register={register} required="Ce champ est requis" setValue={setValue}/>
            {errors.doc && <p className="text-red-500">{errors.doc.message}</p>}
              </div>
            </div>
            <div className="mb-2 sm:mb-1">
              <label
                htmlFor={"description"}
                className="text-sm font-medium mb-2 sm:mb-1 text-[var(--nonaryTextColor)]"
              >
                Description
              </label>
              <textarea
                id={"description"}
                name={"description"}
                rows="3"
                cols="30"
                placeholder={"Entrez une description"}
                {...register("description", { required: "La description est requise" })}
                className={"w-full px-3 py-2 border border-[#CACED8] rounded focus:outline-none text-sm text-[var(--quaterioTextColor)]"}
              ></textarea>
              {errors.description && <p className="text-red-500">{errors.description.message}</p>}
            </div>
          </div>
          <div className="flex items-center justify-end sm:py-0 py-4 px-4 gap-4 rounded-b">
            <button
              className="text-[var(--secondary-color)] border border-[var(--secondary-color)] rounded-lg
                      font-medium px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
              type="button"
              onClick={onClick}
            >
              Annuler
            </button>
            <button
              type="submit"
              data-testid="btnId"
              disabled={isButtonDisabled}
              className={`text-white border border-[var(--secondary-color)] rounded-lg
                bg-[var(--secondary-color)] font-medium px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1
                ${isButtonDisabled ? "cursor-not-allowed" : ""}`}>
              {postLoading ? (
                <div className="flex items-center gap-1">
                  Créer
                  <FiLoader className="text-white text-xl animate-spin" />
                </div>
              ) : (
                "Créer"
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AjoutProjet;
