import React, { useState } from "react";
import Projets from "components/Projet";
import Titre from "components/Titre";
import { useGetProjetByEntrepreneurQuery } from "actions/projets/queries";
import Loader from "components/Loader/Loader";
import { FaPlus } from "react-icons/fa6";
import useCurrentUser from "hooks/useCurrentUser";
import ModalAjout from "layouts/modal/ModalAjout"
import AjoutProjet from "./AjoutProjet";

function Projet() {
  const current_user = useCurrentUser();
  const id = current_user?.id;
  // console.log({ id });
  const { data: projectsData, isLoading } = useGetProjetByEntrepreneurQuery(id);
  const projects = projectsData?.results || [];

  // Trier les projets par date décroissante
  const sortedProjects = projects.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  const secteursUniques = sortedProjects.reduce((acc, projetFiltre) => {
    const secteurName = projetFiltre?.secteur?.[0]?.name;
    if (secteurName && !acc.includes(secteurName)) {
      acc.push(secteurName);
    }
    return acc;
  }, []);

  const [selectedSecteur, setSelectedSecteur] = useState("");
  const filteredProjects = sortedProjects.filter((project) => {
    if (selectedSecteur && project.secteur?.[0]?.name !== selectedSecteur) {
      return false;
    }
    return true;
  });

  const [showModal, setShowModal] = React.useState(false);

  return (
    <div>
      <div>
        <Titre titre={"Projets"} />
        <div className="flex justify-between items-center p-3 pt-0 sm:block sm:px-3">
          <div className="">
            <select
              name="etapeA"
              value={selectedSecteur}
              onChange={(e) => setSelectedSecteur(e.target.value)}
              className="form control w-full sm:mb-2 sm:mb-1 px-2 py-2 border border-[var(--secondary-color)] rounded outline-0 text-[var(--secondary-color)]"
            >
              <option value="">Sélectionner un secteur</option>
              {secteursUniques?.map((secteur, index) => (
                <option key={index} value={secteur}>
                  {secteur}
                </option>
              ))}
            </select>
          </div>
          <div className="">
            <div className="">
              <button className="flex items-center gap-1 px-8 py-3 rounded-full
             bg-[var(--secondary-color)] text-white text-[12px]"
                type="button"
                onClick={() => setShowModal(true)}>
                <FaPlus className="text-xl" />
                <span>Ajouter un projet</span>
              </button>
            </div>
            <ModalAjout titre="Ajouter un projet"
              onClose={() => setShowModal(false)}
              showModal={showModal}>
              <AjoutProjet onClick={() => setShowModal(false)} />
            </ModalAjout>
          </div>
        </div>
        <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-3 p-2">
          {isLoading ? (
            <Loader />
          ) : filteredProjects.length > 0 ? (
            filteredProjects.map((item) => (
                <Projets
                key={item.id}
                  Link={`/projetEntrepreneur/${item.id}`}
                  Video={item?.url_demo}
                  Secteur={item?.secteur}
                  Statut={item?.statut}
                  Titre={item?.title}
                  Financement={item?.montant}
                  Montant={item?.montant_part}
                  className={"hidden"}
                  bgColor={"var(--secondary-color)"}
                />
            ))
          ) : (
            <div>Aucun projet disponible.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Projet;