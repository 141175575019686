import React from "react"
import { IoIosClose } from "react-icons/io";

const ModalAjout = ({
    titre,
    showModal,
    onClose,
    children
}) => {
    return (
        <>
            {
                showModal && (
                    <div
                        className="justify-center p-10 pb-0 mb-O m-5 items-center flex overflow-x-hidden overflow-y-auto fixed top-[1.75rem] left-[1.75rem] right-0
                  inset-0 z-50 outline-none focus:outline-none sm:top-8"
                    >
                        <div className="relative mt-6 w-auto mx-auto max-w-3xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-center justify-between px-5 py-2 rounded-t">
                                    <h3 className="text-sm text-[var(--quaterioTextColor)] font-medium">
                                        {titre}
                                    </h3>
                                    <button
                                        className=""
                                        onClick={onClose}
                                    >
                                        <IoIosClose className="text-[var(--quaterioTextColor)] hover:rounded-lg  font-medium
                    hover:bg-[var(--quaterioTextColor)] hover:text-white text-3xl leading-none outline-none focus:outline-none"/>
                                    </button>
                                </div>
                                {children}
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default ModalAjout
