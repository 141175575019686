

/**
 * @readonly
 * ! PUBLIC ROUTES
 */
export const PUBLIC_PATH = "/";
export const LOGIN_PATH = "login";
export const REGISTER_PATH = "signup";
export const FORGETPASSWORD = "auth/forgetPassword";
export const RESETPASSWORD = "aut/resetPassword";

/**
 * @readonly
 * ! PROTECTED ROUTES
 */
export const ACCEUIL_PATH = "/";

// Les routes qui concerne la partie incubateur
export const INCUBATEUR_PATH = "incubateur";
export const PROJETS_PATH = "projets";
export const DETAILS_PATH = "projets/:id";
export const PROJETS_FINANCES = "projetsFinances";
export const DETAIL_PROJETS_FINANCES = "projetsFinances/:id";
export const LEVER_FONDS = "leverFonds";
export const RAPPORT = "rapports";
export const PROFIL_INCUBATEUR = "profil";

// Les routes qui concerne la partie investisseur
export const INVESTISSEUR_PATH = "investisseur";
export const PROJET_INVESTISSEUR = "projetsInvest";
export const PROJET_DETAIL_INVESTISSEUR = "projetsInvest/:id";
export const RAPPORT_INVESTISSEUR = "rapportInvest";
export const PROJET_FINANCER_INVESTISSEUR = "projetFinancerInvest";
export const PROJET_FINANCER_DETAIL = "projetFinancerInvest/:id";
export const PROFIL_Invest = "profilInvest";

// Les routes qui concerne la partie entrepreneur
export const ENTREPRENEUR_PATH = "dashboard_entrepreneur";
export const PROJET_PATH_ENTREPRENEUR = "projetEntrepreneur";
export const PROJET_PATH_ENTREPRENEUR_Detail = "projetEntrepreneur/:id"
export const RAPPORTEntrepreneur = "rapportEntrepreneur"
export const PROFIL_Entrepreneur = "profilEntrepreneur"

// Les routes qui concerne la partie entrepreneur
export const DEATAILS_PATH_ENTREPRENEUR = "projets";

// La route qui conserne les Porteurs de projets
export const PORTEURS_PROJET_PATH = "porteurs_projets";
export const LISTES_PORTEURS_PROJET_PATH = "porteurs_projet";

// La route qui conserne les Porteurs de projets


/**
 * @readonly
 * ! NOT FOUND ROUTE
 */
export const NOTFOUND_PATH = "/*";
